import {AllConstants} from '../constants/AllConstants';
import {SendGetData} from '../service/fetchData';

export const UsersAction =  {
    UserSearch,

};

function UserSearch(keyword = '', page = 0, pagination = 10, userType = '',ptype='user') {
    return dispatch => {
        SendGetData(`/all-users-list?s=${keyword}&user_type=${userType}&page=${page}&pagination=${pagination}&ptype=${ptype}`)
            .then(response => {
                let usersListAll = {};
                if( response.data ){
                    let totalRecord = response.data.pagination.total;
                    let per_page = response.data.pagination.per_page;
                    totalRecord = Math.ceil(totalRecord / per_page);
                    usersListAll = {
                        usersList: response.data.data,
                        pagination: response.data.pagination,
                        totalRecord: totalRecord,
                        per_page: per_page,
                        isLoading: false
                    }
                }
                dispatch({ type: AllConstants.API_MESSAGE, payload: '' })
                dispatch({ type: AllConstants.USERS_LIST, payload: usersListAll });
            });
    }
}