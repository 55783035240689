import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { AuthAction } from '../../actions';

class Logout extends Component {
    constructor(props) {
        super(props);
        this.props.logouts();      
    }
 
    render(){
        return( <Redirect to={'/auth/login'}/> );
    }
}

function mapState(state) {
    const { isLogin } = state.AuthReducer;
    return { isLogin };
}

const actionCreators = {
    logouts: AuthAction.logouts,
};

export default connect(mapState, actionCreators)(Logout);
//export default connectedLogout;