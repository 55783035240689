import React, { Component } from 'react';
import { SendPostData, showErroMsg, showErroMsgAlert } from '../../service/fetchData';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Input,
  
  } from "reactstrap";

class ChangePassword extends Component {
  
  constructor(props, context) {
    super(props, context);
    this.state = {}
  }


  componentDidMount() {
    let user_id = this.props.user_id ? this.props.user_id : 0;
    this.setState({user_id:user_id});
  }  

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value 
    });
  }  

  dataSubmit = e => {
    e.preventDefault();
    /* let password=encodeURIComponent(window.btoa(JSON.stringify(this.state.password)));
    let password_confirmation=encodeURIComponent(window.btoa(JSON.stringify(this.state.password_confirmation)));
    let data_pas = {
      password1:password,
      password_confirmation1:password_confirmation
    }; */
    SendPostData('/change-password', this.state)
      .then(response => {
        if(response.data.success!==false){
          let errorItems = showErroMsgAlert(response.data.data)
          this.setState({ errorMsg: errorItems })
        }else{
          let errorItems = showErroMsg(response.data.data)
          this.setState({ errorMsg: errorItems })
        }
        
      });
  }

    render() {
        return (
          <>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Change Password</CardTitle>
                </CardHeader>
                <CardBody>
                <div className="alert_bs_div">{this.state.errorMsg}</div>
                    <FormGroup className={`has-label`}>
                        <label>New Password</label>
                        <Input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="off"
                            onChange={this.handleChange}
                        />
                        
                    </FormGroup>
                    <FormGroup className={`has-label`} >
                      <label>Confirm Password</label>
                      <Input
                        id="password_confirmation"
                        name="password_confirmation"
                        type="password"
                        autoComplete="off"
                        value={this.state.password_confirmation}
                        onChange={this.handleChange}
                      />
                      
                    </FormGroup>
                </CardBody>
                <CardFooter className="text-right">
                    <Button color="primary" onClick={this.dataSubmit}>
                      Save Password
                    </Button>
                </CardFooter>
            </Card>
          </>
        );
    }
}

export default ChangePassword;