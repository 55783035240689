import {AllConstants} from '../constants/AllConstants';

let isLoading = {isLoading:true};

const initialState =  {};

const SiteReducers = (state = initialState, action) => {
    switch (action.type) {
        
        case AllConstants.SITE_CONFIGS:
            return{
               ...state,
               site_configs: action.payload,
               isLoading: action.isLoading     
            } 
                
        default:
            return state            
    }
};

export default SiteReducers;