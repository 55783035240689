import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import ImageCropper from "../components/ImageCropper";
import {UploadsURL} from '../../constants/Config';
import { SendPostData, SendGetData, showErroMsg, showLoader,showErroMsgAlert } from '../../service/fetchData';
import logo from "assets/img/react-logo.png";
import { SiteActions } from "../../actions";



class AddAppConfig extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        // register form
        app_configs:{},
		  create_message:''
        
      };
	 }

	 componentDidMount =()=>{
		let id = (this.props.match.params.id) ? this.props.match.params.id : 0;
		this.getSiteConfigs();
		if(id>0){
			this.getAppwithID(id);
		}
	 }

	 getSiteConfigs=()=>{

		SendGetData('/get-siteconfig')
		.then(result => {
		  if(result){
			 this.setState({
				app_configs: result.data,
				isLoading: false,
			 })  
		  }
		})
		.catch(function (error) {
			 console.log(error);
		});
	 }

	 getAppwithID=(id)=>{

		SendGetData('/get-appwithid/'+id)
		.then(result => {
		  if(result){
			  console.log(result.data);
			 this.setState({
				app_configs: result.data,
				isLoading: false,
			 })  
		  }
		})
		.catch(function (error) {
			 console.log(error);
		});
    }
    
    htmlAlert = () => {
      
      this.setState({
        alert: (
          <ReactBSAlert
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="Select logo"
            onConfirm={() => this.submitLogo()}
            onCancel={() => this.hideAlert('c')}
            btnSize=""
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Submit"
            cancelBtnText="Cancel"
          >
            <ImageCropper cropImg={this.cropImgs} ImgName='logo' width={150} height={152} />
          </ReactBSAlert>
        ),
      });
    };
    submitLogo = (e) => {
      let data={
        id:this.state.app_configs.id,
        site_logo:this.state.new_logo
      }
      SendPostData('/save-logo', data)
      .then(response => {
		  this.props.siteConfigs();
        let updateUserData = Object.assign({}, this.state.app_configs, { site_logo: response.data.site_logo })
        this.setState({
            app_configs: updateUserData,
            alert:null
        });
      })
   }
    hideAlert = (t) => {
      if(t==='c'){
         let updateData = Object.assign({}, this.state.app_configs, { 'site_logo': null })

         this.setState({
            new_logo: '',
            app_configs: updateData,
            alert: null,
         });
      }else{
         let updateData = Object.assign({}, this.state.app_configs, { 'site_logo': this.state.new_logo })

         this.setState({
            alert: null,
            app_configs: updateData
         });
      }
      
    }

    cropImgs = (img, imgName) => {
      //if(img){

      this.setState({
          new_logo: img,
      });
      //}
    }

	 handleChange = e => {
		let updateData = Object.assign({}, this.state.app_configs, { [e.target.name]: e.target.value })
		this.setState({
			app_configs: updateData
		});
  }
	 handleFormSubmit = e => {
		e.preventDefault(); 
		this.setState({isLoading: true});      
		SendPostData('/save-appconfig',this.state.app_configs)
		  .then(result => {
				if(result.data.success!==false){
					this.props.siteConfigs();
               let errorItems = showErroMsgAlert(result.data.data);
               this.setState({
                create_message:errorItems,
                 isLoading: false
               });
				  /* window.location.href = "/admin/app-configs"; */
				}else{
				  let errorItems = showErroMsg(result.data.data);
				  this.setState({
					 create_message:errorItems,
					 isLoading: false
				  });
				}
			 
		  })
		  .catch(error => this.setState({ error: error.message }));
	 };
    render() {
       if(this.state.isLoading===true){
          return showLoader();
       }else{
       let app_configs=this.state.app_configs;
       let site_logo=logo;
       if(app_configs.site_logo!==null){
         site_logo=UploadsURL+app_configs.site_logo;
       }
       
        return(
            <>
				<div className="content">
					<Row>
						<Col md="12">
							<Form id="RegisterValidation">
									<Card>
										<CardHeader>
											<CardTitle tag="h4">Add/Edit App Config</CardTitle>
                                 <Button
                                    className="btn-round"
                                    color="default"
                                    onClick={this.htmlAlert}
                                    outline
                                 >
                                    Change Logo
                                 </Button>
                                 {this.state.alert}
										</CardHeader>
										<CardBody>
											<Row>
												{this.state.create_message!='' && 
												<Col md="12"><div className="alert_bs_div">{this.state.create_message} </div></Col>}
												<Col md="6">
													<Card>
														<CardBody>
															<FormGroup>
																<label>Organization Name</label>
																<Input type="text" name="app_name" value={app_configs.app_name} onChange={this.handleChange} />
															</FormGroup>
															<FormGroup>
																<label>Phone Number</label>
																<Input type="text" name="contact_phone" value={app_configs.contact_phone} onChange={this.handleChange} />
															</FormGroup>
															<FormGroup>
                                                <label>Contact Address</label>
                                                <Input
                                                   className="textarea"
                                                   type="textarea"
                                                   cols="80"
												   rows="4"
												   name="contact_address"
                                                   value={app_configs.contact_address}
                                                   onChange={this.handleChange} 
                                                />
															</FormGroup>
														</CardBody>
														
													</Card>
												</Col>
												<Col md="6">
													<Card>
														<CardBody>
															<FormGroup>
																<label>Domain Name</label>
																<Input type="text" name="app_domain" value={app_configs.app_domain} onChange={this.handleChange} />
															</FormGroup>
															<FormGroup>
																<label>Email</label>
																<Input type="email" name="contact_email" value={app_configs.contact_email} onChange={this.handleChange} />
															</FormGroup>
															<FormGroup>
																<label>Logo</label><br/>
                                                {site_logo!='' && <img src={site_logo} width="150"/>}
                                               
                                             </FormGroup>
															
														</CardBody>
														
													</Card>
												</Col>
											</Row>
											<Row>
											</Row>
											
										</CardBody>
									
										<CardFooter className="text-center">
											<Button color="info" className="mr-3" onClick={this.handleFormSubmit}>
												Submit
											</Button>
											{/* <Link className="btn btn-primary" to="/admin/app-configs">Cancel</Link>  */}
										</CardFooter>
									</Card>
							</Form>
						</Col>
					</Row>   
					</div> 
            </>
        )
       }
    }
}
function mapState(state) {
   let { site_configs, isLoading } = state.SiteReducers;
   const { apiMessage } = state.AlertReducer;
   return { site_configs, isLoading, apiMessage };
 }
 
 const actionCreators = {
   siteConfigs: SiteActions.siteConfigs,
   /* profileUpdate: SiteActions.profileUpdate,
   profilePictureUpdate: SiteActions.profilePictureUpdate */
 };
 export default connect(mapState, actionCreators)(AddAppConfig);

/* export default AddAppConfig; */