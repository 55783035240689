import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { SendPostData, SendGetData, showErroMsg, showLoader, showErroMsgAlert } from '../../service/fetchData';
import qr_img from "../../assets/img/Qr-code.jpg";


class AddTopic extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        // register form
        topic_data:{
          status:1
        },
		  create_message:'',
          isLoading:true,
          allYears:[],
          allClasses:[],
          allSubjects:[],
          allTerms:{},
          disabled_st:'disabled'
      };
	 }

	 componentDidMount =()=>{
		let id = (this.props.match.params.id) ? this.props.match.params.id : 0;
		if(id>0){
			this.gettopicwithID(id);
		}else{
			this.setState({isLoading:false})
		}
        this.getAllYears();
        this.getAllTerms();
        this.getAllClasses();
        this.getAllSubjects();
	 }

     getAllYears=()=>{
      this.setState({isLoading:true})
        SendGetData('/get-active-years')
        .then(result => {
          if(result){
           this.setState({
            allYears: result.data,
            isLoading: false,
           });
          }
        })
        .catch(function (error) {
           console.log(error);
        });
    }
    getAllClasses=()=>{
      this.setState({isLoading:true})
        SendGetData('/get-active-class')
        .then(result => {
          if(result){
           this.setState({
            allClasses: result.data,
            isLoading: false,
           });
          }
        })
        .catch(function (error) {
           console.log(error);
        });
    }
    getAllTerms=()=>{
      this.setState({isLoading:true})
        SendGetData('/get-active-terms')
        .then(result => {
          if(result){
           this.setState({
            allTerms: result.data,
            isLoading: false,
           });
          }
        })
        .catch(function (error) {
           console.log(error);
        });
    }
    getAllSubjects=()=>{
      this.setState({isLoading:true})
        SendGetData('/get-active-subjects')
        .then(result => {
          if(result){
           this.setState({
            allSubjects: result.data,
            isLoading: false,
           });
          }
        })
        .catch(function (error) {
           console.log(error);
        });
    }
        

	 gettopicwithID=(id)=>{
    this.setState({isLoading:true})
		SendGetData('/get-topicwithid/'+id)
		.then(result => {
		  if(result){
			  console.log(result.data);
			 this.setState({
				topic_data: result.data,
				isLoading: false,
        disabled_st:''
			 })  
		  }
		})
		.catch(function (error) {
			 console.log(error);
		});
    }

    handleChange = (e, stateName, type='') => {
        switch (type) {
          
          case "length":
            if (this.verifyLength(e.target.value, 2)) {
              this.setState({ [stateName + "State"]: "has-success" });
            } else {
              this.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
            case "url":
              if (this.verifyUrl(e.target.value)) {
                this.setState({ [stateName + "State"]: "has-success" });
              } else {
                this.setState({ [stateName + "State"]: "has-danger" });
              }
              break;  
            
            default:
            break;
        }
        let updateData = Object.assign({}, this.state.topic_data, { [e.target.name]: e.target.value })
        this.setState({
            topic_data: updateData
        });
          
    }
    onRadioValueChange(e) {
      let updateData = Object.assign({}, this.state.topic_data, { term_id: e.target.value })
        this.setState({
            topic_data: updateData
        });
      
    }
    verifyLength = (value, length) => {
        if (value.length >= length) {
          return true;
        }
        return false;
    };
    verifyUrl = (value) => {
      console.log(value);
      if(value==='' || value===undefined)
      return false;
      try {
        new URL(value);
        return true;
      } catch (_) {
        return false;
      }
    };
	 handleFormSubmit = e => {
		e.preventDefault(); 
		this.setState({isLoading: true}); 
    let tpc_d=this.state.topic_data; 
    console.log(this.verifyUrl(tpc_d.media_link));
    if(this.verifyUrl(tpc_d.media_link) && tpc_d.term_id!=='' && tpc_d.term_id!==undefined && tpc_d.year!=='' && tpc_d.year!==undefined && tpc_d.topic_name!=='' && tpc_d.topic_name!==undefined && tpc_d.subject_id!=='' && tpc_d.subject_id!==undefined && tpc_d.class_id!=='' && tpc_d.class_id!==undefined) {
		SendPostData('/save-topic',this.state.topic_data)
		  .then(result => {
				if(result.data.success!==false){
                
                /* this.setState({
                    create_message:errorItems,
                    isLoading: false,
                    
                }); */
                if(this.state.topic_data.topic_id>0){
                  let errorItems = showErroMsgAlert(result.data.data);
                  this.setState({
                    create_message:errorItems,
                    isLoading: false,
                    
                });
                }else{
                  window.location.href = "/admin/edit-topic/"+result.data.id;
                }
				  /* window.location.href = "/admin/app-configs"; */
				}else{
				  let errorItems = showErroMsg(result.data.data);
				  this.setState({
					 create_message:errorItems,
					 isLoading: false
				  });
				}
      
			 
		  })
		  .catch(error => this.setState({ error: error.message }));
    }else{
      if(tpc_d.term_id==='' || tpc_d.term_id===undefined){
        this.setState({ "term_idState": "has-danger",isLoading:false });
      }
      if(tpc_d.class_id==='' || tpc_d.class_id===undefined){
        this.setState({ "class_idState": "has-danger",isLoading:false });
      }
      if(tpc_d.subject_id==='' || tpc_d.subject_id===undefined){
        this.setState({ "subject_idState": "has-danger",isLoading:false });
      }
      if(tpc_d.topic_name==='' || tpc_d.topic_name===undefined){
        this.setState({ "topic_nameState": "has-danger",isLoading:false });
      }
      if(tpc_d.year==='' || tpc_d.year===undefined){
        this.setState({ "yearState": "has-danger",isLoading:false });
      }
      if(!this.verifyUrl(tpc_d.media_link)){
        this.setState({ "media_linkState": "has-danger",isLoading:false });
      }
      
      
    }
	 };
    render() {
        let all_years=this.state.allYears;
        let all_terms=this.state.allTerms;
        let all_subjects=this.state.allSubjects;
        let all_classes=this.state.allClasses;
        let b_list='';
        let topic_data=this.state.topic_data;
        let qrcode=qr_img;
        let qrname = '';
        if (topic_data.qrcode!==undefined) qrcode = 'data:image/png;base64,' + topic_data.qrcode;
        if (topic_data.topic_name!==undefined){
          qrname = topic_data.topic_name.replace(/\s+/g, '-').toLowerCase() + '-' + topic_data.qrcode_id + '.png';
        } 
       if(this.state.isLoading===true ){
          return showLoader();
       }else{
           
       
       b_list = Object.keys(all_terms).map((page, k) => {
        let u_k='rdo_'+all_terms[k].term_id;
       
          return (
            <div className="form-check-radio" key={u_k}>
              <Label check>
                <Input
                  
                  defaultValue={all_terms[k].term_id}
                  name="term_id"
                  type="radio"
                  onChange={(e) =>
                    this.onRadioValueChange(e)
                } 
                checked={this.state.topic_data.term_id == Number(all_terms[k].term_id)}
                />
                {all_terms[k].term_name} <span className="form-check-sign" />
              </Label>
            </div>
          )
        })
       
       const status_options=[
        { value: 1, label: "Active" },
        { value: 0, label: "Inactive" },
      ];
      
        return(
            <>
				<div className="content">
					<Row>
						<Col md="12">
							<Form id="RegisterValidation" className="add_topic_form">
									<Card>
										<CardHeader>
											<CardTitle tag="h4">Add/Edit Topic Data</CardTitle>
                      {qrcode &&
                            <Col md="12" className="qrdiv">
                              <div className="qrcde_inner">
                              <a href={ qrcode } download={qrname} className="btn btn-info mr-3" disabled={this.state.disabled_st}>Download QR Code</a>
                                <img alt="QR Code" src= { qrcode } width="100" />
                                {topic_data.qrcode_id && <p className="qrcodeid mt-2">{topic_data.qrcode_id}</p> }
                              </div> 
                            </Col>
                        }
										</CardHeader>
										<CardBody>
											<Row>
                      
												{this.state.create_message!='' && 
												<Col md="12"><div className="alert_bs_div">{this.state.create_message} </div></Col>}
												<Col md="6">
                                                    <FormGroup className={`has-label ${this.state.yearState}`}>
                                                            <label>Year*</label>
                                                            <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="year"
                                                                value={all_years.filter(function(option) {
                                                                return option.value == Number(topic_data.year);
                                                                })}
                                                                onChange={(value) =>{
                                                                let updateUserData = Object.assign({}, this.state.topic_data, { year: value.value })
                                                                this.setState({ topic_data: updateUserData })
                                                                }
                                                                }
                                                                options={all_years}
                                                                placeholder="Years"
                                                            />
                                                            {this.state.yearState === "has-danger" ? (
                                                        <label className="error">Please select year</label>
                                                        ) : null}
                                                        </FormGroup>
                                                        <FormGroup className={`has-label ${this.state.class_idState}`}>
                                                            <label>Class*</label>
                                                            <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="class_id"
                                                                value={all_classes.filter(function(option) {
                                                                return option.value === Number(topic_data.class_id);
                                                                })}
                                                                onChange={(value) =>{
                                                                let updateUserData = Object.assign({}, this.state.topic_data, { class_id: value.value })
                                                                this.setState({ topic_data: updateUserData })
                                                                }
                                                                }
                                                                options={all_classes}
                                                                placeholder="Class"
                                                            />
                                                            {this.state.class_idState === "has-danger" ? (
                                                        <label className="error">Please select class</label>
                                                        ) : null}
                                                        </FormGroup>
                                                    <FormGroup className={`has-label ${this.state.topic_nameState}`}>
                                                        <label>Topic*</label>
                                                        <Input type="text" name="topic_name" value={topic_data.topic_name}  onChange={(e) =>
                                                            this.handleChange(e, "topic_name", "")
                                                        } />
                                                        {this.state.topic_nameState === "has-danger" ? (
                                                        <label className="error">Please enter topic</label>
                                                        ) : null}
                                                    </FormGroup>
															
														
												</Col>
												<Col md="6">
                                                <FormGroup className={`has-label ${this.state.term_idState}`}>
                                                        <label>Term*</label>
                                                        {/* <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="term_id"
                                                            value={all_terms.filter(function(option) {
                                                            return option.value === Number(topic_data.term_id);
                                                            })}
                                                            onChange={(value) =>{
                                                            let updateUserData = Object.assign({}, this.state.topic_data, { term_id: value.value })
                                                            this.setState({ topic_data: updateUserData })
                                                            }
                                                            }
                                                            options={all_terms}
                                                            placeholder="Term"
                                                        /> */}
                                                       <div className="topic_term_radio"> {b_list} </div>
                                                       {this.state.term_idState === "has-danger" ? (
                                                        <label className="error">Please select term</label>
                                                        ) : null}
                                                    </FormGroup>
                                                    <FormGroup className={`has-label ${this.state.subject_idState}`}>
                                                        <label>Subject*</label>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="subject_id"
                                                            value={all_subjects.filter(function(option) {
                                                            return option.value === Number(topic_data.subject_id);
                                                            })}
                                                            onChange={(value) =>{
                                                            let updateUserData = Object.assign({}, this.state.topic_data, { subject_id: value.value })
                                                            this.setState({ topic_data: updateUserData })
                                                            }
                                                            }
                                                            options={all_subjects}
                                                            placeholder="Subject"
                                                        />
                                                         {this.state.subject_idState === "has-danger" ? (
                                                        <label className="error">Please select subject</label>
                                                        ) : null}
                                                    </FormGroup >
                                                    <FormGroup className={`has-label ${this.state.media_linkState}`}>
                                                        <label>Media Link*</label>
                                                        <Input type="text" name="media_link" value={topic_data.media_link}  onChange={(e) =>
                                                            this.handleChange(e, "media_link", "url")
                                                        } />
                                                        {this.state.media_linkState === "has-danger" ? (
                                                        <label className="error">Please enter valid media link</label>
                                                        ) : null}
                                                    </FormGroup>
                                                    {/* <FormGroup>
                                                        <label>Status</label>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="status"
                                                            value={status_options.filter(function(option) {
                                                            return option.value === Number(topic_data.status);
                                                            })}
                                                            onChange={(value) =>{
                                                            let updateUserData = Object.assign({}, this.state.topic_data, { status: value.value })
                                                            this.setState({ topic_data: updateUserData })
                                                            }
                                                            }
                                                            options={status_options}
                                                            placeholder="Status"
                                                        />
                                                    </FormGroup> */}
                                                    

												</Col>
                                                <Col md="12">
                                                <FormGroup className={`has-label`}>
                                                        <label>Comments</label>
                                                        <Input type="textarea" name="comment_msg" value={topic_data.comment_msg}  onChange={(e) =>
                                                            this.handleChange(e, "comment_msg", "")
                                                        } />
                                                        
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                <Button color="info" className="mr-3" onClick={this.handleFormSubmit}>
												Submit
											</Button>
											<Link className="btn btn-info" to="/admin/topics">Cancel</Link> 
                                                </Col>
											</Row>
											
											
										</CardBody>
									
										
									</Card>
							</Form>
						</Col>
					</Row>   
					</div> 
            </>
        )
       }
    }
}


export default AddTopic;