import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";

import { SendPostData, SendGetData, showErroMsg } from '../../service/fetchData';


class AddServerConfig extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        // register form
        server_configs:{},
		  create_message:''
        
      };
	 }

	 componentDidMount =()=>{
		let id = (this.props.match.params.id) ? this.props.match.params.id : 0;
		if(id>0){
			this.getAppwithID(id);
		}
	 }
	 


	 getAppwithID=(id)=>{

		SendGetData('/get-serverwithid/'+id)
		.then(result => {
		  if(result){
			  console.log(result.data);
			 this.setState({
				server_configs: result.data,
				isLoading: false,
			 })  
		  }
		})
		.catch(function (error) {
			 console.log(error);
		});
	 }

	 handleChange = e => {
		let updateData = Object.assign({}, this.state.server_configs, { [e.target.name]: e.target.value })
		this.setState({
			server_configs: updateData
		});
  }
	 handleFormSubmit = e => {
		e.preventDefault(); 
		this.setState({isLoading: true});      
		SendPostData('/save-serverconfig',this.state.server_configs)
		  .then(result => {
				if(result.data.success!==false){
				  
				  window.location.href = "/admin/server-configs";
				}else{
				  let errorItems = showErroMsg(result.data.data);
				  this.setState({
					 create_message:errorItems,
					 isLoading: false
				  });
				}
			 
		  })
		  .catch(error => this.setState({ error: error.message }));
	 };
    render() {
		 let server_configs=this.state.server_configs;
        return(
            <>
				<div className="content">
					<Row>
						<Col md="12">
							<Form id="RegisterValidation">
									<Card>
										<CardHeader>
											<CardTitle tag="h4">Add/Edit Server Config</CardTitle>
										</CardHeader>
										<CardBody>
											<Row>
												{this.state.create_message!='' && 
												<Col md="12">{this.state.create_message}</Col>}
												<Col md="6">
													<Card>
														<CardBody>
															<FormGroup>
																<label>Server Name</label>
																<Input type="text" name="app_name" value={server_configs.app_name} onChange={this.handleChange} />
															</FormGroup>
															<FormGroup>
																<label>Instance ID </label>
																<Input type="text" name="contact_phone" value={server_configs.contact_phone} onChange={this.handleChange} />
															</FormGroup>
															
														</CardBody>
														
													</Card>
												</Col>
												<Col md="6">
													<Card>
														<CardBody>
															<FormGroup>
																<label>Location</label>
																<Input type="text" name="app_domain" value={server_configs.app_domain} onChange={this.handleChange} />
															</FormGroup>
															<FormGroup>
																<label>Country</label>
																<Input type="email" name="contact_email" value={server_configs.contact_email} onChange={this.handleChange} />
															</FormGroup>
															
															
														</CardBody>
														
													</Card>
												</Col>
											</Row>
											
											
										</CardBody>
									
										<CardFooter className="text-center">
											<Button color="primary" className="mr-3" onClick={this.handleFormSubmit}>
												Submit
											</Button>
											<Link className="btn btn-primary" to="/admin/server-configs">Cancel</Link> 
										</CardFooter>
									</Card>
							</Form>
						</Col>
					</Row>   
					</div> 
            </>
        )
    }
}
export default AddServerConfig;