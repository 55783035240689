import Intake from "views/admin/Intake";
import IntakeDashboard from "views/intake/IntakeDashboard";

const intakeSidemenu =[{
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: IntakeDashboard,
    layout: "/intake",
  },
  
    {
    path: "/intake",
    name: "Intakes",
    icon: "nc-icon nc-badge",
    component: Intake,
    layout: "/intake",
    }
]

export default intakeSidemenu;