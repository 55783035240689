import React from "react";


// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

function Home() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="wrapper">
        <Header title="Linking Voice" desc=""/>
        <div className="section">
          Test
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
