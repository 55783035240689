import React, { Component } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
  } from "reactstrap";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { SendPostData, SendGetData, showErroMsg,showLoader } from '../../service/fetchData';
import $ from "jquery";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

class AddEmailContent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            email_title: '',
            email_content: '',
          email_subject:'',
        create_message:'',
        page_head:'Create New Email Content',
        id:0,
        isLoading: false,
        }
        
      }
      handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
       
    }


    handleCheckClick = (e) => {
      this.setState({
        [e.target.name]: e.target.value
    });
    }

    onEditorChange= (e) => {
      this.setState({email_content: e.target.value})
  }
    onChange=(e)=>{
        let files=e.target.files;
        
      let reader= new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload=(e)=>{ 
        this.setState({
          featured_img: e.target.result,
          page_img:e.target.result
      });
      }
    }

    handleFormSubmit = e => {
        e.preventDefault(); 
        this.setState({isLoading: true});      
        SendPostData('/save-emailist',this.state)
          .then(result => {
              if(result.data.success!==false){
                
                window.location.href = "/admin/emails-content";
              }else{
                let errorItems = showErroMsg(result.data.data);
                this.setState({
                  create_message:errorItems,
                  isLoading: false
                });
              }
            
          })
          .catch(error => this.setState({ error: error.message }));
      };
      componentDidMount(){
        var that = this;
      $('#textfield').summernote({
        codemirror: {
          mode: 'text/html',
          htmlMode: true,
          lineNumbers: true,
          lineWrapping: true,
          theme: 'monokai'
        },
        callbacks : {
          onChange: function(contents) {
            that.setState({email_content: contents});
          }
        }
      })
        let id = this.props.match.params.id;
        if(id>0){
            /* this.setState({isLoading:true}); */
          SendGetData('/get-emailwithid/'+id)
          .then(result => {
            if(result){

              $('.note-editable').html(result.data.email_content);
              this.setState({
                email_title: result.data.email_title,
                email_content: result.data.email_content, 
                email_subject :result.data.email_subject,
                page_head:'Edit Email Content',
                id:result.data.id,
                isLoading: false,
              })  
            }
          })
          .catch(function (error) {
              console.log(error);
          });
        }else{
          this.setState({isLoading: false});
         
        }
        
      }
    render(){
        if( this.state.isLoading ){
            return showLoader();
        }else{
        return(
            <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form id="RegisterValidation">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add/Edit User</CardTitle>
                  </CardHeader>
                  <CardBody>
                  <FormGroup className={`has-label`}>
                      <label>Email title *</label>
                      <Input
                        id="email_title"
                        name="email_title"
                        type="text"
                        autoComplete="off"
                        value={this.state.email_title} onChange={this.handleChange}
                      />
                      {this.state.email_title === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                    
                    <FormGroup
                      className={`has-label `}
                    >
                      <label>Email Subject</label>
                      <Input
                        id="email_subject"
                        name="email_subject"
                        type="text"
                        autoComplete="off"
                        value={this.state.email_subject} onChange={this.handleChange}
                      />
                      {this.state.email_subject ===
                      "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup
                      className={`has-label `}
                    >
                    <textarea id="textfield" name="email_content"  placeholder="Content" className="form-control editor1"  onChange={this.onEditorChange}  ></textarea>
                    </FormGroup>
                    <div className="category form-category">
                      * Required fields
                    </div>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="primary" onClick={this.handleFormSubmit}>
                      Submit
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
            
          </Row>
        </div>
      </>


        );
        }
    }
}
export default AddEmailContent;