import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import AlertReducer from './AlertReducer';
import UsersReducer from './UsersReducer';
import SiteReducers from './SiteReducers';

const MainReducer = combineReducers({
    AuthReducer: AuthReducer,
    AlertReducer: AlertReducer,
    UsersReducer:UsersReducer,
    SiteReducers:SiteReducers
});

export default MainReducer;