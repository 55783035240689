import {AllConstants} from '../constants/AllConstants';
import { fetchGetData } from '../service/fetchData';

export const SiteActions =  {
    siteConfigs
};

function siteConfigs() {
    return dispatch => {
        fetchGetData(`/get-siteconfig`)
        .then(response => {
            localStorage.setItem('siteData',JSON.stringify(response.data));
            dispatch({
                type: AllConstants.SITE_CONFIGS,
                payload: response.data,
                isLoading:response.isLoading
            });
            dispatch({ type: AllConstants.API_MESSAGE, payload: '' })
        });  
    }  
}