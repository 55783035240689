import { AllConstants } from '../constants/AllConstants';

const UsersReducer = (state = {}, action) => {
    switch (action.type) {
        case AllConstants.USERS_LIST:
            return{
                ...state,
                usersData: action.payload        
            }
        case AllConstants.USERS_DELETE:    
/*           Object.assign({},state.restaurantsData.restaurantsList, state.restaurantsData.restaurantsList.filter( obj => {
               return obj.restaurant_id !== action.restaurantId
           } )); */
            return{
               ...state,
               usersData: state.usersData
            }   
        case AllConstants.NEWS_USERS_LIST:
            return{
                ...state,
                newsUsersData: action.payload        
            }                     
        default:
            return state            
    }
};

export default UsersReducer;