
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {createStore, applyMiddleware} from 'redux';
import MainReducer from './reducers';


import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
/* import Frontend from "layouts/Frontend"; */
import Owner from "layouts/Owner";
import Intake from "layouts/Intake";
import Frontend from "layouts/Frontend";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/custom_style.css";

const hist = createBrowserHistory();
let store = createStore(MainReducer, applyMiddleware(thunk));

/* ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Redirect to="/admin/dashboard" />
    </Switch>
  </Router>,
  document.getElementById("root")
); */

ReactDOM.render(<Provider store={store}><Router history={hist}>
  <Switch>
    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
    <Route path="/owner" render={(props) => <Owner {...props} />} />
    <Route path="/intake" render={(props) => <Intake {...props} />} />
    <Route path="/user-profile" render={(props) => <Frontend {...props} />} />
    <Route path="/media-link" render={(props) => <Frontend {...props} />} />
    <Route path="/" render={(props) => <AuthLayout {...props} />} />
    <Redirect to="/" />
  </Switch>
</Router></Provider>, document.getElementById('root'));
