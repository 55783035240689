
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  FormGroup,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { UsersAction } from '../../actions';
import {  showLoader,SendGetData } from '../../service/fetchData';
import Pagination from '../../components/Common/Pagination';
import Select from "react-select";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecord:0,
      per_page:0,
      offset:0,
      pageNo:1,
      page:0,
      pagination:{},
      isLoading:true,
      roleList:[],
      access_id:'',
      keyword:''
    }
  }
    componentDidMount=()=>{
      this.getAllRoles();
      this.props.UserSearch( '', this.state.page, 10);
    }
    componentWillReceiveProps(nextProps) {
      this.setState(nextProps);
     // this.props.UserSearch( this.state.keyword, this.state.offset, 10);
  } 

  handleKeypress = e => {
    //it triggers by pressing the enter key
  if (e.keyCode === 13) {
    this.findSearch();
  }
};

  handleChange = e => {

    this.setState({
      [e.target.name]: e.target.value
    });
} 


  getAllRoles = () => {
    SendGetData('/get-active-roles', {})
      .then(result => {
        let data=result.data;
            let response=[];
            let response_val={};
            for(let i=0; i<data.length; i++){
              response_val={
                value:data[i].id,
                label:data[i].role
              }
              response.push(response_val);
            }
            this.setState({
              roleList: response,
              isLoading: result.isLoading
            })
  
      })
  }
  
  findSearch =(e)=>{
    e.preventDefault();
    let access_id='';
    if(this.state.access_id!=''){access_id=this.state.access_id;}
    this.props.UserSearch( this.state.keyword, 0, 10,access_id);
  }
  ResetFn=()=>{
    this.setState({keyword:'',access_id:''},()=>{
      this.props.UserSearch( this.state.keyword, 0, 10,this.state.access_id);
    });
    
  }

  
  handlePageClick = data => {
    this.setState({
        usersData: '',
        offset: 0,
    })        
    let selected = data.selected;
    let pageNo = Math.ceil(selected * this.props.per_page)+1;
    let offset = data.selected + 1;
    let access_id='';
    this.setState({
        isLoading:true,
        offset:offset,
        pageNo:pageNo,
        page:selected
    })
    //this.props.RestaurantListOwner(offset);
    if(this.state.access_id!=''){access_id=this.state.access_id;}
      this.props.UserSearch( this.state.keyword, offset, 10,access_id);
}
  render() {
    let { usersData,roleList,access_id } = this.state;

        let totalRecord = 0;
        let users_list = '';
        if( usersData ){
            let usersList  = usersData.usersList;
            
            if(usersList){
                totalRecord = usersData.totalRecord;
                let n  = this.state.pageNo;
                users_list = Object.keys(usersList).map( k => {
                    let id = usersList[k].id;
                    let t_id="tooltip_st_"+id;
                    let url = `/admin/edit-user/${id}/${this.state.offset}`;
                    let active_class = 'btn-sm btn-round btn-icon btn btn-success';
                    let st='Active';
                    if (Number(usersList[k].status) === 0) {st='Pending';}
                    if (Number(usersList[k].status) === 2) {st='Inactive';}
                    if (Number(usersList[k].status) === 4) {st='Deleted';}
                    if(Number(usersList[k].status) !== 1 ){
                        active_class = 'btn-sm btn-round btn-icon btn btn-youtube';
                    }

                    return (
                      <tr key={id}>
                        <td className="text-center">{n++}</td>
                        <td>{ usersList[k].name}</td>
                        <td>{usersList[k].email}</td>
                        <td>{usersList[k].phone}</td>
                        <td>{usersList[k].role}</td>
                        <td>
                        <span className={active_class} id={t_id}>
                        </span>
                        <UncontrolledTooltip
                            delay={0}
                            target={t_id}
                          >
                            {st}
                          </UncontrolledTooltip>
                        </td>
                        <td className="text-right">
                        <Link className="btn-sm btn-icon btn-primary" to={url} id="tooltip366246651">
                            <i className="fa fa-edit" />
                          </Link>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246651"
                          >
                            Edit
                          </UncontrolledTooltip>

                            {/*  <Link className="btn-sm btn-icon btn-primary" to={url} id="tooltip476609793">
                             <i className="fa fa-times" />
                          </Link>
                            
                          {" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip476609793"
                          >
                            Delete
                          </UncontrolledTooltip> */}
                        </td>
                       
                      </tr>
                                       
                    )
                })
            }else{
              users_list = <p>Data Not Found</p>
            }
             
        } 
        if(this.state.isLoading){
          return showLoader();
        }else{
    return (
      
      <>
      
        <div className="content">
          <Row>
            
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="10">
                    <CardTitle tag="h4">Users</CardTitle>
                    </Col>
                    <Col md="2" className="text-right">
                    <Link className="btn btn-info" to="/admin/add-user"> 
                      <span className="btn-label">
                        <i className="nc-icon nc-simple-add" />
                      </span>
                      Add
                    </Link>
                    </Col>
                    </Row>
                    <form onSubmit={this.dishSearch}>
                      <Row>
                    <Col md="4">
                    <FormGroup
                      className={`has-label `}
                    >
                      <label>User Roles</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="access_id"
                        value={roleList.filter(function(option) {
                          return Number(option.value) === Number(access_id);
                        })}
                        onChange={(value) =>{
                          this.setState({ access_id: Number(value.value) })
                        }
                        }
                        options={roleList}
                        placeholder="User Role Search"
                      />
                    
                      </FormGroup>
                    </Col>
                    
                    <Col md="4">
                      <label>Keyword</label>
                      <input type="text" name="keyword" placeholder="Search" className="form-control" onChange={this.handleChange}  value={this.state.keyword} />

                    </Col>
                    <Col md="1" className="mr-5">
                      <Button color="primary" type="submit" onClick={this.findSearch}>
                        Search
                      </Button>
                    </Col>
                    <Col md="1">
                      <Button color="primary" onClick={this.ResetFn}>
                        Reset
                      </Button>
                    </Col>
                    </Row>
                    </form>
                </CardHeader>
                <CardBody>
                  <Table responsive striped>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>User Role</th>
                        <th>Status</th>
                        <th >Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {users_list}
                     
                    </tbody>
                  </Table>
                  {(totalRecord>0 && <Pagination total={totalRecord} click={this.handlePageClick} pageNumber={this.state.page} />)} 
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
        }
  }
}

/* export default Users; */
function mapState(state) {
  let per_page = 0;
  let page = 0;
  let pageNo = 1;    
  let apiMessage = '';
  let { usersData } = state.UsersReducer;
  let isLoading=true;
  if (usersData) {
      isLoading = usersData.isLoading;
      per_page = usersData.per_page;
      page = usersData.pagination.current_page - 1;
      pageNo = Math.ceil(page * per_page)+1;        
      apiMessage = state.AlertReducer.apiMessage;
  }

  return { apiMessage, usersData, per_page, page, pageNo,isLoading };
}

const actionCreators = {
  UserSearch:UsersAction.UserSearch,
  DelUser:UsersAction.DelUser,
  MigrateUser:UsersAction.MigrateUser
};

export default connect(mapState, actionCreators)(Users);
