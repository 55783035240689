
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import { Redirect } from 'react-router';

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";
import {authCheckModule} from '../service/fetchData';

var ps;

class Pages extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        if(prop.path==="/"){
          return (
            <Route
              path={prop.path}
              component={prop.component}
              key={key}
            />
          );

        }else{
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        
      } else {
        return null;
      }
    });
  };
  render() {
    let currentUser = this.props.user;
    if (currentUser!==undefined && (Number(currentUser.access_id) === 1 || Number(currentUser.access_id) === 2)) {
      return (<Redirect to='/admin/dashboard' />)
    }else if (currentUser!==undefined && Number(currentUser.access_id) === 3) {
      return (<Redirect to='/intake/dashboard' />)
    }else{
      
      return (
        <>
          <AuthNavbar />
          <div className="wrapper wrapper-full-page" ref="fullPages">
            <div className="full-page section-image">
              <Switch>{this.getRoutes(routes)}</Switch>
              <Footer fluid />
            </div>
          </div>
        </>
      );
    }
  }
}

export default Pages;
