import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { SendPostData, SendGetData, showErroMsg, showLoader } from '../../service/fetchData';


class AddUserRole extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        // register form
        role_data:{},
		  create_message:'',
          isLoading:true
      };
	 }

	 componentDidMount =()=>{
		let id = (this.props.match.params.id) ? this.props.match.params.id : 0;
		if(id>0){
			this.getRolewithID(id);
		}else{
			this.setState({isLoading:false})
		}
	 }


	 getRolewithID=(id)=>{

		SendGetData('/get-rolewithid/'+id)
		.then(result => {
		  if(result){
			  console.log(result.data);
			 this.setState({
				role_data: result.data,
				isLoading: false,
			 })  
		  }
		})
		.catch(function (error) {
			 console.log(error);
		});
    }

	 handleChange = e => {
		let updateData = Object.assign({}, this.state.role_data, { [e.target.name]: e.target.value })
		this.setState({
			role_data: updateData
		});
  }
	 handleFormSubmit = e => {
		e.preventDefault(); 
		this.setState({isLoading: true});      
		SendPostData('/save-roles',this.state.role_data)
		  .then(result => {
              this.setState({isLoading:false})
				if(result.data.success!==false){
					this.props.siteConfigs();
               let errorItems = showErroMsg(result.data.data);
               this.setState({
                 create_message:errorItems,
                 isLoading: false
               });
				  /* window.location.href = "/admin/app-configs"; */
				}else{
				  let errorItems = showErroMsg(result.data.data);
				  this.setState({
					 create_message:errorItems,
					 isLoading: false
				  });
				}
			 
		  })
		  .catch(error => this.setState({ error: error.message }));
	 };
    render() {
       if(this.state.isLoading===true){
          return showLoader();
       }else{
           
       let role_data=this.state.role_data;
       
       const status_options=[
        { value: 1, label: "Active" },
        { value: 0, label: "Inactive" },
      ];
        return(
            <>
				<div className="content">
					<Row>
						<Col md="12">
							<Form id="RegisterValidation">
									<Card>
										<CardHeader>
											<CardTitle tag="h4">Add/Edit Role Data</CardTitle>

										</CardHeader>
										<CardBody>
											<Row>
												{this.state.create_message!='' && 
												<Col md="12">{this.state.create_message}</Col>}
												<Col md="6">
													<Card>
														<CardBody>
															<FormGroup>
																<label>Role</label>
																<Input type="text" name="role" value={role_data.role} onChange={this.handleChange} />
															</FormGroup>
															
														</CardBody>
														
													</Card>
												</Col>
												<Col md="6">
													<Card>
														<CardBody>
                                                        <FormGroup>
                                                            <label>Status</label>
                      <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="status"
                            value={status_options.filter(function(option) {
                              return option.value === Number(role_data.status);
                            })}
                            onChange={(value) =>{
                              let updateUserData = Object.assign({}, this.state.role_data, { status: value.value })
                              this.setState({ role_data: updateUserData })
                            }
                            }
                            options={status_options}
                            placeholder="Status"
                          />
                    </FormGroup>
															
														</CardBody>
														
													</Card>
												</Col>
											</Row>
											
											
										</CardBody>
									
										<CardFooter className="text-center">
											<Button color="primary" className="mr-3" onClick={this.handleFormSubmit}>
												Submit
											</Button>
											<Link className="btn btn-primary" to="/admin/user-roles">Cancel</Link> 
										</CardFooter>
									</Card>
							</Form>
						</Col>
					</Row>   
					</div> 
            </>
        )
       }
    }
}


export default AddUserRole;