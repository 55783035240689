
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { UsersAction } from '../../actions';
import {  showLoader } from '../../service/fetchData';


class AdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecord:0,
      per_page:0,
      offset:0,
      pageNo:1,
      page:0,
      pagination:{},
      isLoading:true
    }
  }
    componentDidMount=()=>{
      this.props.UserSearch( '', this.state.page, 10,2,'admin');
    }
    componentWillReceiveProps(nextProps) {
      this.setState(nextProps);
     // this.props.UserSearch( this.state.keyword, this.state.offset, 10);
  }     
  render() {
    let { usersData } = this.state;
        let totalRecord = 0;
        let users_list = '';
        if( usersData ){
            let usersList  = usersData.usersList;
            
            if(usersList){
                totalRecord = usersData.totalRecord;
                let n  = this.state.pageNo;
                let uData=JSON.parse(localStorage.getItem('userData'));
                users_list = Object.keys(usersList).map( k => {
                    let id = usersList[k].id;
                    let url = `/admin/edit-admin-user/${id}/${this.state.offset}`;
                    let active_class = 'btn-sm btn-round btn-icon btn btn-success';
                    let st='Active';
                    let t_id="tooltip_st_"+id;
                    if (Number(usersList[k].status) === 0) {st='Pending';}
                    if (Number(usersList[k].status) === 2) {st='Inactive';}
                    if (Number(usersList[k].status) === 4) {st='Deleted';}
                    if(Number(usersList[k].status) !== 1 ){
                        active_class = 'btn-sm btn-round btn-icon btn btn-youtube';
                    }

                    return (
                      <tr key={id}>
                        <td className="text-center">{n++}</td>
                        <td>{ usersList[k].name}</td>
                        <td>{usersList[k].email}</td>
                        <td className="text-center">{usersList[k].phone}</td>
                        <td className="text-right">
                        <span className={active_class} id={t_id}>
                        </span>
                        <UncontrolledTooltip
                            delay={0}
                            target={t_id}
                          >
                            {st}
                          </UncontrolledTooltip>
                        </td>
                        {/* (Number(uData.access_id)===1) && */
                        <td className="text-right">
                        <Link className="btn-sm btn-icon btn-info" to={url} id="tooltip366246651">
                            <i className="fa fa-edit" />
                          </Link>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246651"
                          >
                            Edit
                          </UncontrolledTooltip>

                             {/* <Link className="btn-sm btn-icon btn-primary" to={url} id="tooltip476609793">
                             <i className="fa fa-times" />
                          </Link>
                            
                          {" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip476609793"
                          >
                            Delete
                          </UncontrolledTooltip> */}
                        </td>
                        }
                      </tr>
                                       
                    )
                })
            }else{
              users_list = <p>Data Not Found</p>
            }
             
        } 
        if(this.state.isLoading){
          return showLoader();
        }else{
          let uData=JSON.parse(localStorage.getItem('userData'));
    return (
      
      <>
      
        <div className="content">
          <Row>
            
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="10">
                    <CardTitle tag="h4">Admin Users</CardTitle>
                    </Col>
                  <Col md="2" className="text-right">
                    <Link className="btn btn-info" to="/admin/add-admin-user"> 
                      <span className="btn-label">
                        <i className="nc-icon nc-simple-add" />
                      </span>
                      Add
                    </Link>
                    </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                  <Table responsive striped>
                    <thead className="text-info">
                      <tr>
                        <th className="text-center">#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th className="text-center">Phone</th>
                        <th className="text-right">Status</th>
                        {(Number(uData.access_id)===1) &&
                        <th className="text-right">Actions</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                    {users_list}
                     
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
        }
  }
}

/* export default Users; */
function mapState(state) {
  let per_page = 0;
  let page = 0;
  let pageNo = 1;    
  let apiMessage = '';
  let { usersData } = state.UsersReducer;
  let isLoading=true;
  if (usersData) {
      isLoading = usersData.isLoading;
      per_page = usersData.per_page;
      page = usersData.pagination.current_page - 1;
      pageNo = Math.ceil(page * per_page)+1;        
      apiMessage = state.AlertReducer.apiMessage;
  }

  return { apiMessage, usersData, per_page, page, pageNo,isLoading };
}

const actionCreators = {
  UserSearch:UsersAction.UserSearch,
  DelUser:UsersAction.DelUser,
  MigrateUser:UsersAction.MigrateUser
};

export default connect(mapState, actionCreators)(AdminUsers);
