import {AllConstants} from '../constants/AllConstants';
import {FetchData, showErroMsg, fetchGetData, SendGetData, SendPostData, showErroMsgAlert} from '../service/fetchData';

export const AuthAction =  {
    login,
    forgotPassword,
    logouts,
    profileInfoAdmin,
    profileUpdate,
    profileInfo,
    UserprofileUpdate
};

function login (data) {
    return dispatch => {
        if( data.email && data.password && data.email!==undefined && data.password!==undefined){
            FetchData('/auth/login', data)
            .then((userData) => {
                if( userData.data.success ){
                    localStorage.setItem('userData',JSON.stringify(userData.data.data));
                    dispatch({ type: AllConstants.LOGIN_SUCCESS, payload:userData.data.data });
                    dispatch({ type: AllConstants.API_MESSAGE, payload: '' })
                }else{
                    dispatch({ type: AllConstants.API_MESSAGE, payload: showErroMsg(userData.data.data) })
                }
            })            
        }else{
            dispatch({ type: AllConstants.API_MESSAGE, payload: 'Username and Password Required.' })
        }
    }
}
function forgotPassword( email ) {
    return dispatch => {
        fetchGetData(`/auth/forgot-password/${email}`)
        .then(response => {
            dispatch({ type: AllConstants.API_MESSAGE, payload: showErroMsg(response.data.data) })
        });  
    }  
}

function logouts() {
    return dispatch => {
        localStorage.setItem('userData','');
        /* localStorage.setItem('searchData','');
        localStorage.setItem('intro',''); */
        localStorage.clear();
        dispatch({ type: AllConstants.LOGOUT_SUCCESS });
        //this.props.history.push('/login');        
    }
}

function profileInfoAdmin( id = 0 ) {
    return dispatch => {
        SendGetData(`/user-profile-info-admin?id=${id}`)
        .then(response => {
            dispatch({
                type: AllConstants.GET_USER_PROFILE_ADMIN,
                payload: response.data,
                isLoading:false
            });
        });  
    }  
}

function profileUpdate( data,id ) {
    return dispatch => {
        SendPostData('/profile-update', data)
        .then(response => {
            dispatch({ type: AllConstants.API_MESSAGE, payload: showErroMsgAlert(response.data.data) })
            profileInfoAdmin(id);
        });        
    }
}

function profileInfo( id = 0 ) {
    return dispatch => {
        SendGetData(`/user-profile-info?id=${id}`)
        .then(response => {
            console.log(response);
            localStorage.setItem('userData',JSON.stringify(response.data));
            dispatch({
                type: AllConstants.GET_USER_PROFILE,
                payload: response.data,
                isLoading:response.isLoading
            });
        });  
    }  
}

function UserprofileUpdate( data ) {
    return dispatch => {
        SendPostData('/profile-update', data)
        .then(response => {
            dispatch({ type: AllConstants.API_MESSAGE, payload: showErroMsg(response.data.data) })
            this.profileInfo();
        });        
    }
}


