
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import { AuthAction } from '../actions';
import { connect } from 'react-redux';
import routes from "routes.js";

var ps;

class Frontend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
    };
  }
  componentDidMount() {
    console.log('cvbd');
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/front") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
        <>
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <Switch>{this.getRoutes(routes)}</Switch>
          </div>  
        </>
      );
  }
}

/* export default Admin; */
function mapState(state) {
  const { isLogin, user } = state.AuthReducer;
  return { isLogin, user };
}

const actionCreators = {
  login: AuthAction.login,
};

export default connect(mapState, actionCreators)(Frontend);
