import React, { Component } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import AvatarEditor from 'react-avatar-editor'

class ImageCropper extends Component {
    editor= AvatarEditor;
    constructor(props) {
        super(props);
        this.state = {
            src: '',
            imgs:'',
            croppedImageUrl: '',
            cropValue:false,
            width: 250,
            height: 250,
            rotate:0
        }
    }
  
    componentDidMount() {
        if( this.props.width ){
            this.setState({width: this.props.width});   
        }
        if( this.props.height ){
            this.setState({height: this.props.height});
        }
    }  
    
    closeSrc = () => {
        this.setState({
            src: '',
            rotate:0
        });
        
    }    

    onChange = (e) => {
        this.closeSrc();
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            this.setState({
                src: e.target.result,
            });
        }
    }


setEditorRef = (editor) => {
    if (editor) {
       this.editor = editor;
   }
}

handleSave = data => {
    let img = this.editor.getImageScaledToCanvas().toDataURL();
    this.props.cropImg(img, this.props.ImgName );
}

onImgLoad = () =>{
    let img = this.editor.getImageScaledToCanvas().toDataURL();
    this.props.cropImg(img, this.props.ImgName );
}

rotateRight = e => {
    e.preventDefault()
    this.setState({
      rotate: this.state.rotate + 90,
    })
}


    render() {
        return (
            <div className="container crop-area">
                <div className="row">
                    <div className="col-md-6"><input type="file" name="upload_image" id="upload_image" onChange={this.onChange}  /></div>
                    {/* <div className="col-md-6">                      
                        {this.state.src && <div className='site-btn-a form-btn' onClick={this.closeSrc}>Cancel</div>}  
                        {this.state.src && <div className='site-btn-a form-btn' onClick={this.rotateRight}>Rotate</div>}  
                    </div>       */}              
                    <div className="col-md-12">
{/*                         <ReactCrop
                            src={this.state.src}
                            crop={this.state.crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange} 
                        />  */} 

                <AvatarEditor
                    image={this.state.src}
                    width={this.state.width}
                    height={this.state.height}
                    border={10}
                    color={[0, 0, 0, 1]} // RGBA
                    rotate={this.state.rotate}
                    ref={(ref) => this.setEditorRef(ref)}
                    onPositionChange={this.handleSave}
                    onImageReady={this.onImgLoad}
                />
                
                
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageCropper;