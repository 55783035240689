import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import DropdownFixedNavbar from "components/Navbars/DropdownFixedNavbar.js";

// core components

class AboutUsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      pageHeader:React.createRef()
    };
  }
 /*  componentDidMount=()=>{
    let pageHeader = this.state.pageHeader;
    React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  } */
 render(){
  
  
  return (
    <>
    <DropdownFixedNavbar />
      <div className="page-header page-header-small">
       {/*  <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg32.jpg") + ")",
          }}
          ref={this.state.pageHeader}
        ></div> */}
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 className="title">{this.props.title}</h1>
              <h4>
                {this.props.desc}
              </h4>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
}

export default AboutUsHeader;
