
import Buttons from "views/components/Buttons.js";
import Calendar from "views/Calendar.js";
import Charts from "views/Charts.js";
import Dashboard from "views/Dashboard.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import GridSystem from "views/components/GridSystem.js";
import Icons from "views/components/Icons.js";
import LockScreen from "views/pages/LockScreen.js";
import Login from "views/pages/Login.js";
import Logout from "views/pages/Logout.js";
import Notifications from "views/components/Notifications.js";
import Panels from "views/components/Panels.js";
import ReactTables from "views/tables/ReactTables.js";
import Register from "views/pages/Register.js";
import RegularForms from "views/forms/RegularForms.js";
import RegularTables from "views/tables/RegularTables.js";
import SweetAlert from "views/components/SweetAlert.js";
import Timeline from "views/pages/Timeline.js";
import Typography from "views/components/Typography.js";
import UserProfile from "views/pages/UserProfile.js";
import ValidationForms from "views/forms/ValidationForms.js";
import VectorMap from "views/maps/VectorMap.js";
import Widgets from "views/Widgets.js";
import Wizard from "views/forms/Wizard.js";
import ForgotPassword from "views/pages/ForgotPassword";
import ResetPassword from "views/pages/ResetPassword";
import Users from "views/admin/Users";
import AddUser from "views/admin/AddUser";
import EmailsContent from "views/admin/EmailsContent";
import AddEmailContent from "views/admin/AddEmailContent";
import AppConfigs from "views/admin/AppConfigs";
import AddAppConfig from "views/admin/AddAppConfig";
import ServerConfig from "views/admin/ServerConfig";
import AddServerConfig from "views/admin/AddServerConfig";
import VerifyEmail from "views/pages/VerifyEmail";
import OwnerDashboard from "views/owner/OwnerDashboard";
import UserRoles from "views/admin/UserRoles";
import AdminUsers from "views/admin/AdminUsers";
import AddUserRole from "views/admin/AddUserRole";
import Home from "views/pages/Home";
import Intake from "views/admin/Intake";
import AddIntake from "views/admin/AddIntake";
import AddAdminUser from "views/admin/AddAdminUser";
import ViewIntake from "views/admin/ViewIntake";
import Years from "views/admin/Years";
import AddYear from "views/admin/AddYear";
import Terms from "views/admin/Terms";
import AddTerm from "views/admin/AddTerm";
import Subjects from "views/admin/Subjects";
import AddSubject from "views/admin/AddSubject";
import Classes from "views/admin/Classes";
import AddClass from "views/admin/AddClass";
import Topics from "views/admin/Topics";
import AddTopic from "views/admin/AddTopic";
import MediaPage from "views/pages/MediaPage";

const routes = [
  
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Topics,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-calendar-60",
    component: OwnerDashboard,
    layout: "/intake",
  },
  {
    path: "/home",
    name: "Home",
    icon: "nc-icon nc-bank",
    component: Home,
    layout: "/front",
  },

  {
    collapse: true,
    name: "Users",
    icon: "nc-icon nc-circle-10",
    state: "userCollapse",
    views: [
      {
        path: "/users",
        name: "All Users",
        mini: "AU",
        component: Users,
        layout: "/admin",
      },
      {
        path: "/add-user/:id/:no",
        name: "Add User",
        mini: "ADU",
        component: AddUser,
        layout: "/admin",
      },
      {
        path: "/add-user",
        name: "Add User",
        mini: "ADU",
        component: AddUser,
        layout: "/admin",
      },
      {
        path: "/edit-user/:id/:no",
        name: "Edit User",
        mini: "EDU",
        component: AddUser,
        layout: "/admin",
      },
      {
        path: "/add-admin-user",
        name: "Add Admin User",
        mini: "ADU",
        component: AddAdminUser,
        layout: "/admin",
      },
      {
        path: "/edit-admin-user/:id",
        name: "Edit Admin User",
        mini: "EDU",
        component: AddAdminUser,
        layout: "/admin",
      },
      {
        path: "/user-roles",
        name: "User Roles",
        mini: "UR",
        component: UserRoles,
        layout: "/admin",
      },
      {
        path: "/add-role",
        name: "Add Role",
        mini: "AR",
        component: AddUserRole,
        layout: "/admin",
      },
      {
        path: "/edit-role/:id",
        name: "Edit Role",
        mini: "ER",
        component: AddUserRole,
        layout: "/admin",
      },
      {
        path: "/admin-users",
        name: "Admin Users",
        mini: "AU",
        component: AdminUsers,
        layout: "/admin",
      },
    ]
    },
    {
      collapse: true,
      name: "App Configs",
      icon: "nc-icon nc-app",
      state: "AppCollapse",
      views: [
        {
          path: "/app-configs",
          name: "App Config",
          mini: "AC",
          component: AppConfigs,
          layout: "/admin",
        },
        {
          path: "/add-app-config",
          name: "Add App",
          mini: "ADU",
          component: AddAppConfig,
          layout: "/admin",
        },
        {
          path: "/edit-app-config/:id",
          name: "Edit App",
          mini: "EDU",
          component: AddAppConfig,
          layout: "/admin",
        },
      ]
      },
      {
        collapse: true,
        name: "Server Config",
        icon: "fa fa-server",
        state: "ServerCollapse",
        views: [
          {
            path: "/server-configs",
            name: "Server Config",
            mini: "SC",
            component: ServerConfig,
            layout: "/admin",
          },
          {
            path: "/add-server-config",
            name: "Add Server",
            mini: "ADS",
            component: AddServerConfig,
            layout: "/admin",
          },
          {
            path: "/edit-app-config/:id",
            name: "Edit App",
            mini: "EDU",
            component: AddAppConfig,
            layout: "/admin",
          },
        ]
        },  
    {
      collapse: true,
      name: "Email Management",
      icon: "nc-icon nc-email-85",
      state: "emailCollapse",
      views: [
        {
          path: "/emails-content",
          name: "All Contents",
          mini: "AC",
          component: EmailsContent,
          layout: "/admin",
        },
        {
          path: "/add-email-content",
          name: "Add Email Content",
          mini: "ADE",
          component: AddEmailContent,
          layout: "/admin",
        },
        {
          path: "/edit-email-content/:id",
          name: "Edit Email Content",
          mini: "EEC",
          component: AddEmailContent,
          layout: "/admin",
        },
      ]
      },

  {
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        path: "/timeline",
        name: "Timeline",
        mini: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/logout",
        name: "Logout",
        mini: "LO",
        component: Logout,
        layout: "/auth",
      },
      {
        path: "/lock-screen",
        name: "LockScreen",
        mini: "LS",
        component: LockScreen,
        layout: "/auth",
      },
      {
        path: "/reset-password/:token",
        name: "ResetPassword",
        mini: "RP",
        component: ResetPassword,
        layout: "/auth",
      },
      {
        path: "/verify-email/:token",
        name: "Verify Email",
        mini: "VE",
        component: VerifyEmail,
        layout: "/auth",
      },
      {
        path: "/media-link/:id",
        name: "Media Page",
        mini: "MP",
        component: MediaPage,
        layout: "/front",
      },
      {
        path: "/user-profile",
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/front",
      },
      {
        path: "/forgot-password",
        name: "Forgot Password",
        mini: "FP",
        component: ForgotPassword,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    icon: "nc-icon nc-layout-11",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    icon: "nc-icon nc-ruler-pencil",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/intake",
        name: "Intake",
        mini: "EF",
        component: Intake,
        layout: "/admin",
      },
      {
        path: "/intake",
        name: "Intake",
        mini: "EF",
        component: Intake,
        layout: "/admin",
      },
      {
        path: "/years",
        name: "Years",
        mini: "AY",
        component: Years,
        layout: "/admin",
      },
      
      {
        path: "/add-year",
        name: "Add Year",
        mini: "AY",
        component: AddYear,
        layout: "/admin",
      },
      {
        path: "/edit-year/:id",
        name: "Edit Year",
        mini: "EY",
        component: AddYear,
        layout: "/admin",
      },
      {
        path: "/terms",
        name: "Terms",
        mini: "AY",
        component: Terms,
        layout: "/admin",
      },
      {
        path: "/add-term",
        name: "Add Term",
        mini: "AT",
        component: AddTerm,
        layout: "/admin",
      },
      {
        path: "/edit-term/:id",
        name: "Edit Term",
        mini: "ET",
        component: AddTerm,
        layout: "/admin",
      },
      {
        path: "/subjects",
        name: "Subjects",
        mini: "AS",
        component: Subjects,
        layout: "/admin",
      },
      {
        path: "/add-subject",
        name: "Add Subject",
        mini: "AS",
        component: AddSubject,
        layout: "/admin",
      },
      {
        path: "/edit-subject/:id",
        name: "Edit Subject",
        mini: "ES",
        component: AddSubject,
        layout: "/admin",
      },
      {
        path: "/classes",
        name: "Classes",
        mini: "C",
        component: Classes,
        layout: "/admin",
      },
      {
        path: "/add-class",
        name: "Add Class",
        mini: "AC",
        component: AddClass,
        layout: "/admin",
      },
      {
        path: "/edit-class/:id",
        name: "Edit Class",
        mini: "EC",
        component: AddClass,
        layout: "/admin",
      },
      {
        path: "/topics",
        name: "topics",
        mini: "T",
        component: Topics,
        layout: "/admin",
      },
      {
        path: "/add-topic",
        name: "Add Topic",
        mini: "AT",
        component: AddTopic,
        layout: "/admin",
      },
      {
        path: "/edit-topic/:id",
        name: "Edit Topic",
        mini: "ET",
        component: AddTopic,
        layout: "/admin",
      },
      {
        path: "/add-intake",
        name: "Intake",
        mini: "EF",
        component: AddIntake,
        layout: "/admin",
      },
      {
        path: "/view-intake/:id",
        name: "View Intake",
        mini: "VI",
        component: ViewIntake,
        layout: "/admin",
      },
      {
        path: "/edit-intake/:id",
        name: "Intake",
        mini: "EF",
        component: AddIntake,
        layout: "/admin",
      },
      {
        path: "/intake",
        name: "Intake",
        mini: "EF",
        component: Intake,
        layout: "/intake",
      },
      {
        path: "/add-intake",
        name: "Intake",
        mini: "EF",
        component: AddIntake,
        layout: "/intake",
      },
      {
        path: "/edit-intake/:id",
        name: "Intake",
        mini: "EF",
        component: AddIntake,
        layout: "/intake",
      },
      {
        path: "/view-intake/:id",
        name: "View Intake",
        mini: "VI",
        component: ViewIntake,
        layout: "/intake",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    icon: "nc-icon nc-single-copy-04",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        mini: "RT",
        component: ReactTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    icon: "nc-icon nc-box",
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "nc-icon nc-chart-bar-32",
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "nc-icon nc-calendar-60",
    component: Calendar,
    layout: "/admin",
  },
  
  
  {
    path: "/",
    name: "Home",
    icon: "nc-icon nc-bank",
    component: Login,
    layout: "/auth",
  },
];

export default routes;
