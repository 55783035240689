
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import { SendGetData, showLoader } from '../../service/fetchData';
import ReactBSAlert from "react-bootstrap-sweetalert";


class AppConfigs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecord:0,
            per_page:0,
            offset:0,
            pageNo:1,
            page:0,
            pagination:{},
            emailList:'',
            isLoading:true,
            alert:null
    }
  }
  getAllApps = () => {
    SendGetData('/get-all-apps', {})
      .then(result => {
        this.setState({
          appList: result.data,
          isLoading: result.isLoading
        })

      })
  }

  warningWithConfirmMessage = (id) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure you want to delete?"
          onConfirm={() => this.delAppConfig(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          
        </ReactBSAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  delAppConfig = id => {
    SendGetData('/app-config-delete/'+id)
        .then(response => {
          this.setState({
            alert: (
              <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deleted!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
              >
                Your imaginary file has been deleted.
              </ReactBSAlert>
            ),
          });
            this.getAllApps();
        }     
        
        );        
}

  componentDidMount() {
    this.getAllApps();
  }
  render() {
    let b_list = '';
    if (this.state.isLoading) {
      b_list = showLoader();
    } else {

      const { appList } = this.state;
      if(appList!=='' && appList!==undefined){
        b_list = Object.keys(appList).map((page, k) => {
            let editURL = "/admin/edit-app-config/" + appList[k].id;
            let u_key = 'app-' + appList[k].id;
            return (
              <tr key={u_key}>
                <td>{appList[k].app_name}</td>
                <td>{appList[k].app_domain}</td>
                <td><Link className="page_editIcon btn-sm btn-icon btn-primary mr-3" to={editURL}><i className="fa fa-edit" aria-hidden="true"></i></Link>
                <span className="btn-sm btn-icon btn-primary" onClick={()=>this.warningWithConfirmMessage(appList[k].id)}><i className="fa fa-times" ></i></span>
                </td>
    
              </tr>
            )
          })
      }
      
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              {this.state.alert!==null && 
                <p>{this.state.alert}</p>
              }
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="10">
                    <CardTitle tag="h4">App Configs</CardTitle>
                    </Col>
                  <Col md="2" className="text-right">
                    <Link className="btn btn-info" to="/admin/add-app-config"> 
                      <span className="btn-label">
                        <i className="nc-icon nc-simple-add" />
                      </span>
                      Add
                    </Link>
                    </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                  
                  <Table responsive striped>
                    <thead className="text-primary">
                      <tr>
                        <th>App Name</th>
                        <th>App Domain</th>
                        
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                     {b_list}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}



export default AppConfigs;
