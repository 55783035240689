
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import { SendGetData, showLoader,showErroMsg,SendPostData } from '../../service/fetchData';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Pagination from '../../components/Common/Pagination';

class Years extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        totalRecord:0,
        per_page:0,
        offset:0,
        pageNo:1,
        page:0,
        pagination:{},
        emailList:'',
        isLoading:true,
        alert:null,
        YearList:'',
        keyword:'',
        edit_id:0
    }
  }
  getAllYears = (page,keyword) => {
    if(page===undefined || page===null){page=1;}
    if(keyword===undefined || keyword===null){keyword='';}
    SendGetData('/get-all-years?page='+page+'&s='+keyword, {})
      .then(result => {
        let totalRecord = result.data.pagination.total;
          let per_page = result.data.pagination.per_page;
          totalRecord = Math.ceil(totalRecord/per_page);
          let page = ( totalRecord < result.data.pagination.current_page ) ? totalRecord : result.data.pagination.current_page - 1;
          this.setState({
            YearList: result.data.data,
            isLoading: false,
            pagination:result.data.pagination,
            page:page,
            totalRecord:totalRecord
          })
        

      })
  }

  handlePageClick = data => {
    let offset = data.selected + 1;
    this.setState({isLoading:true})
    this.getAllYears(offset,this.state.keyword);
  
    }


  deletePP = (id) => {
    this.setState({
        del_id:id,
        alert: (
            <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure you want to delete?"
            onConfirm={() => this.deleteFn()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
            >
            
            </ReactBSAlert>
        ),
    });
  };

    deleteFn=()=>{
        SendGetData('/del-year/'+this.state.del_id)
        .then(result => {
            this.setState({
                alert: (
                  <ReactBSAlert
                    type={result.data.type}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={result.data.message}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                  >
                    {result.data.content}
                  </ReactBSAlert>
                ),
              });
              this.getAllYears( 1,'');
        })
    }


  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };


  componentDidMount() {

    this.setState({keyword:'',last_page:''},()=>{
      this.getAllYears(this.state.last_page,this.state.keyword);
    });
    
    
    
  }
  render() {
    let b_list = '';

    let totalRecord=this.state.totalRecord;
    if (this.state.isLoading) {
      b_list = showLoader();
    } else {

      const { YearList } = this.state;
      if(YearList!=='' && YearList!==undefined){
        let i=0;
        b_list = Object.keys(YearList).map((page, k) => {
          let t_id="tooltip_st_"+ YearList[k].year_id;
            let editURL = "/admin/edit-year/" + YearList[k].year_id;
            let u_key = 'app-' + YearList[k].year_id;
            i+=1;
            return (
              <tr key={u_key}>
                <td>{i}</td>
                <td>{YearList[k].display_year}</td>

                <td>
                <Link className="page_editIcon btn-sm btn-icon btn-info mr-3" to={editURL}><i className="fa fa-edit" aria-hidden="true"></i></Link>
                {<span className="btn-sm btn-icon btn-info" onClick={()=>this.deletePP(YearList[k].year_id)}><i className="fa fa-times" ></i></span>}
                </td>
    
              </tr>
            )
          })
      }
      
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              {this.state.alert!==null && 
                <p>{this.state.alert}</p>
              }
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="10">
                    <CardTitle tag="h4">Years</CardTitle>
                    </Col>
                  <Col md="2" className="text-right">
                      
                      <Link className="" to='/admin/add-year'><span className=" btn btn-info btn-label">
                        <i className="nc-icon nc-simple-add" />Add
                      </span></Link>
                    </Col>
                    </Row>
                    
                </CardHeader>
                <CardBody>
                  
                  <Table responsive striped>
                    <thead className="text-info">
                      <tr>
                        <th>ID</th>
                        <th>Year</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                     {b_list}
                    </tbody>
                  </Table>
                  {(totalRecord>0 && <Pagination total={totalRecord} click={this.handlePageClick} pageNumber={this.state.page} />)} 
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}



export default Years;
