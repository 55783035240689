import { AllConstants } from '../constants/AllConstants';

const initialState = { apiError: '', apiMessage: '' };

const AlertReducer = (state = initialState, action) => {
    switch (action.type) {
        case AllConstants.API_FAILURE:
            return {
                ...state, apiError: action.payload
            }
        case AllConstants.API_MESSAGE:
            return {
                ...state, apiMessage: action.payload
            }
        case AllConstants.API_LOADER:
            return {
                ...state, apiMessage: action.payload
            }
        default:
            return state
    }
};

export default AlertReducer;