import Dashboard from "views/Dashboard";
import AppConfigs from "views/admin/AppConfigs";
import ServerConfig from "views/admin/ServerConfig";
import Users from "views/admin/Users";
import EmailsContent from "views/admin/EmailsContent";
import Home from "views/pages/Home";
import Intake from "views/admin/Intake";
import Years from "views/admin/Years";
import Terms from "views/admin/Terms";
import Subjects from "views/admin/Subjects";
import Classes from "views/admin/Classes";
import Topics from "views/admin/Topics";
import Login from "views/pages/Login";

const sidemenu =[
  
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-th-large",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Topics",
    icon: "fa fa-tasks",
    state: "topicsCollapse",
    views: [
      {
        path: "/topics",
        name: "Add/Edit/Search Topics",
        mini: "AEST",
        component: Topics,
        layout: "/admin",
      },
      
    ]
    },

  {
    collapse: true,
    name: "System Settings",
    icon: "nc-icon nc-settings-gear-65",
    state: "systemCollapse",
    views: [
        
      {
        path: "/add-app-config",
        name: "App Config",
        mini: "AC",
        component: AppConfigs,
        layout: "/admin",
      },
      {
        path: "/years",
        name: "Year",
        mini: "Y",
        component: Years,
        layout: "/admin",
      },
      {
        path: "/terms",
        name: "Terms",
        mini: "T",
        component: Terms,
        layout: "/admin",
      },
      {
        path: "/subjects",
        name: "Subjects",
        mini: "S",
        component: Subjects,
        layout: "/admin",
      },
      {
        path: "/classes",
        name: "Classes",
        mini: "C",
        component: Classes,
        layout: "/admin",
      },
      
    ]
    },
    {
      path: "/admin-users",
      name: "Users",
      mini: "AU",
      component: Users,
      layout: "/admin",
      icon: "nc-icon nc-circle-10",
    },

]

export default sidemenu;
