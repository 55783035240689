import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

class Pagination extends Component {
    render() {
        if( this.props.total <= 1 ) return false;
        let pageNumber = ( this.props.pageNumber ) ? this.props.pageNumber : 0;
        return (
            <div>
                 <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.props.total}
                    marginPagesDisplayed={4}
                    pageRangeDisplayed={4}
                    forcePage={pageNumber}
                    onPageChange={this.props.click}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link custom-page-link'}
                    previousLinkClassName={'page-link custom-page-link'}
                    nextLinkClassName={'page-link custom-page-link'}
                    disabledClassName={'disabled'}
                    breakLinkClassName={'page-link custom-page-link'}
                />   
            </div>
        );
    }
}

export default Pagination;