
import React from "react";

// reactstrap components
/* import {
  
  Container,
  Row,
  Col,
} from "reactstrap";
import Header from "components/Header/Header.js"; */

class IntakeDashboard extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        name:'',
        
    };  
       
  }

  componentDidMount() {
    document.body.classList.toggle("owner-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("owner-page");
  }

  render() {
    return (
      <>
      <div className="owner-page content">
         {/* <Header title="Owner Dashboard" desc=""/>
        <Container>
          <Row>
            <Col  md="12">
              <h1>Coming Soon...!!</h1>
            </Col>
            
          </Row>
        </Container> */}
        
      </div>
      </>
    );
  }
}

export default IntakeDashboard;
