import React, { Component } from 'react';
import { FetchData,fetchGetData, showErroMsg } from '../../service/fetchData';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
  } from "reactstrap";
  import { Link } from "react-router-dom";
class ResetPassword extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            status:0,
            token:0,
           // token:this.props.match.params.token
        }
      }

      
    componentDidMount() {
        let token = this.props.match.params.token;
        let errorItems = '';
        fetchGetData(`/auth/reset-password-validate?token=${token}`)
        .then(response => {
            if( response.data.data !== '' ){
                errorItems = showErroMsg(response.data.data)
            }
            this.setState({ errorMsg: errorItems, status:response.data.status, token:token })
        });
    }      
    
    
    handleChange = e => {
        this.setState({
          [e.target.name]: e.target.value 
        });
    }  

    
    
    dataSubmit = e => {
        e.preventDefault();
        /* let password=encodeURIComponent(window.btoa(JSON.stringify(this.state.password))); */
    /* let password_confirmation=encodeURIComponent(window.btoa(JSON.stringify(this.state.password_confirmation)));
    let data_pas = {
      password1:password,
      password_confirmation1:password_confirmation,
      token :this.state.token
    }; */
        FetchData('/auth/reset-password', this.state)
          .then(response => {
            let errorItems = showErroMsg(response.data.data)
            this.setState({ errorMsg: errorItems,password:'',password_confirmation:'' })
          });
    }

    render() {
        return (
            <div className="forgot-password">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Form action="" className="form" method="">
                    <Card className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <h3 className="header text-center">Reset Password</h3>
                          {this.state.errorMsg}
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="password" value={this.state.password} name="password" placeholder="New Password" className="form-control" onChange={this.handleChange} />
                        </InputGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <input type="password" name="password_confirmation" value={this.state.password_confirmation} placeholder="Confirm Password" className="form-control" onChange={this.handleChange} />
                        </InputGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          className="btn-round mb-3"
                          color="info"
                          onClick={this.dataSubmit}
                        >
                          Submit
                        </Button>
                        <Link  to="/auth/login"> 
                      <span className="btn-label text-info">
                        Login
                      </span>
                    </Link>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
            <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/img_kj.png")})`,
          }}
        />
            </div>
            
        );
    }
}

export default ResetPassword;