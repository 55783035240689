export const AllConstants = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    API_SUCCESS: 'API_SUCCESS',
    API_FAILURE: 'API_FAILURE',
    API_MESSAGE: 'API_MESSAGE',
    API_LOADER:'API_LOADER',
    GET_USER_PROFILE: 'GET_USER_PROFILE',
    SITE_CONFIGS: 'SITE_CONFIGS',
    RESTAURANT_DELETE: 'RESTAURANT_DELETE',
    RESTAURANT_SELECT_OWNER:'RESTAURANT_SELECT_OWNER',
    DISHES_LIST_OWNER:'DISHES_LIST_OWNER',
    DISHES_SEARCH:'DISHES_SEARCH',
    MENU_CATEGORY:'MENU_CATEGORY',
    USERS_LIST:'USERS_LIST',
    GET_USER_PROFILE_ADMIN:'GET_USER_PROFILE_ADMIN',
    NEWS_USERS_LIST:'NEWS_USERS_LIST',
    RESTAURANT_MERGE:'RESTAURANT_MERGE',
    TOTAL_DISHES_RESTAURANT:'TOTAL_DISHES_RESTAURANT'
}