
import React from "react";
import {FetchData,showErroMsg,showLoader} from '../../service/fetchData'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

class Register extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        name:'',
        email:'',
        phone:'',
        password:'',
        password_confirmation:'',
        nameState:'',
        emailState:'',
        phoneState:'',
        passwordState:'',
        password_confirmationState:'',
        agree:1,
        errorMsg: '',
        ownerMsg:'',
        isLoading:true
    };     
  }

  componentDidMount() {
    document.body.classList.toggle("register-page");
    this.setState({isLoading:false})
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  handleChange = (e, stateName, type,length=0) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(e.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "password":
        if (this.verifyLength(e.target.value, 6)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
        case "number":
        if (this.verifyNumber(e.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(e.target.value,length)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      
        default:
        break;
      }
      this.setState({
        [e.target.name]: e.target.value
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  checkData = (e) => {
    if (e.target.checked) {
        this.setState({
            [e.target.name]: true
        });
    } else {
        this.setState({
            [e.target.name]: false
        });
    }
  }

  verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  dataSubmit = (e) => {
    e.preventDefault();
    this.setState({errorMsg:'',isLoading:true});
    if( this.state.name &&
        this.state.email &&
        this.state.password &&
        this.state.agree ){
          let data={
            name:this.state.name,
            email:this.state.email,
            phone:this.state.phone,
            password:this.state.password,
            password_confirmation:this.state.password_confirmation,
            status:2,
            email_verify:0
          }
        FetchData('/auth/register', data)
        .then((userData) => {
            if(userData.data.success){
              let errorItems = showErroMsg({"message":["Thank you for Registering. You are registered as a site owner. A confirmation email has been sent to your registered email. Please verify. Please also check your junk mail for your verification login email."]}) 
                this.setState({
                    errorMsg: errorItems,
                    name:'',
                    email:'',
                    phone:'',
                    password:'',
                    password_confirmation:'',
                    isLoading:false             
                }); 
            }else{
               const errorItems = showErroMsg(userData.data.data);

                this.setState({
                    errorMsg: errorItems  ,
                    isLoading:false                   
                }); 
            }
        })
        .catch(error => {
            this.setState({
              alert:1,
                errorMsg: error   ,
                isLoading:false             
            });                        
        });            
    }else{
      this.setState({isLoading:false })
      if(this.state.name===''){
        this.setState({nameState:'has-danger'})
      }
      if(this.state.email===''){
        this.setState({emailState:'has-danger'})
      }
      if(this.state.phone===''){
        this.setState({phoneState:'has-danger'})
      }
      if(this.state.password===''){
        this.setState({passwordState:'has-danger'})
      }
      if(this.state.password_confirmation===''){
        this.setState({password_confirmationState:'has-danger'})
      }
        
                    
    }   
}
  render() {
    if(this.state.isLoading){
      return showLoader();
    }else{

    let {emailState,nameState,phoneState,passwordState,password_confirmationState}=this.state;
    return (
      <div className="register-page">
        <Container>
          <Row>
            <Col className="ml-auto" lg="5" md="5">
              <div className="info-area info-horizontal mt-5">
                <div className="icon icon-primary">
                  <i className="nc-icon nc-tv-2" />
                </div>
                <div className="description">
                  <h5 className="info-title">Marketing</h5>
                  <p className="description">
                    We've created the marketing campaign of the website. It was
                    a very interesting collaboration.
                  </p>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <div className="icon icon-primary">
                  <i className="nc-icon nc-html5" />
                </div>
                <div className="description">
                  <h5 className="info-title">Fully Coded in HTML5</h5>
                  <p className="description">
                    We've developed the website with HTML5 and CSS3. The client
                    has access to the code using GitHub.
                  </p>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <div className="icon icon-info">
                  <i className="nc-icon nc-atom" />
                </div>
                <div className="description">
                  <h5 className="info-title">Built Audience</h5>
                  <p className="description">
                    There is also a Fully Customizable CMS Admin Dashboard for
                    this product.
                  </p>
                </div>
              </div>
            </Col>
            <Col className="mr-auto" lg="4" md="6">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Register</CardTitle>
                  
                  {/* <div className="social">
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button className="btn-icon btn-round" color="dribbble">
                      <i className="fa fa-dribbble" />
                    </Button>
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fa fa-facebook-f" />
                    </Button>
                    <p className="card-description">or be classical</p>
                  </div> */}
                </CardHeader>
                <CardBody>
                {this.state.errorMsg!==undefined && this.state.errorMsg!='' && 
                <p>{this.state.errorMsg}</p>
              }
                  <Form action="" className="form" method="">
                  <FormGroup className={`has-label ${nameState}`}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Name" type="text" value={this.state.name} onChange={(e) =>
                              this.handleChange(e, "name", "length", 2)
                            } name="name" maxLength={100} />

                    </InputGroup>
                    {this.state.nameState === "has-danger" ? (
                        <label className="error"><br />
                          The name must be between 2 and 100 characters.
                        </label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${emailState}`}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" type="email" value={this.state.email} onChange={(e) =>
                          this.handleChange(e, "email", "email")
                        } name="email"/>
                    </InputGroup>
                    {this.state.emailState === "has-danger" ? (
                        <label className="error"><br />
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${phoneState}`}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-mobile" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Phone" name="phone" type="text" value={this.state.phone} onChange={(e) =>
                          this.handleChange(e, "phone", "number")
                        } name="phone"/>
                    </InputGroup>
                    {this.state.phoneState === "has-danger" ? (
                            <label className="error">
                              Please enter a valid phone number.
                            </label>
                          ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${passwordState}`}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Password" type="password" value={this.state.password} onChange={(e) =>
                          this.handleChange(e, "password", "password")
                        } name="password"/>
                    </InputGroup>
                    {this.state.passwordState === "has-danger" ? (
                        <label className="error">The password must be at least 6 characters.</label>
                      ) : null}
                     </FormGroup> 
                     <FormGroup className={`has-label ${password_confirmationState}`}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Confirm Password" type="password" value={this.state.password_confirmation} onChange={(e) =>
                          this.handleChange(
                            e,
                            "password_confirmation",
                            "password",
                          )} name="password_confirmation"/>
                    </InputGroup>
                    {this.state.password_confirmationState === "has-danger" ? (
                        <label className="error">The password must be at least 6 characters.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup check className="text-left">
                      <Label check>
                        <Input defaultChecked type="checkbox" onClick={this.checkData} value={1} name="agree" />
                        <span className="form-check-sign" />I agree to the{" "}
                        <a href="#pablo" onClick={this.checkData}>
                          terms and conditions
                        </a>
                        .
                      </Label>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={this.dataSubmit}
                  >
                    Register
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>

    );
        }
  }
}

export default Register;
