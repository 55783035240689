
import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  FormGroup
} from "reactstrap";
import { connect } from 'react-redux';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import {  showLoader } from '../../service/fetchData';
import { AuthAction } from '../../actions';
import { Redirect } from 'react-router';


class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        email:'',
        password:'',
        emailState:'',
        passwordState:'',
        errorMsg: {
            type:'',
            msg:''
        },
        ownerMsg:'',
        alert:null,
        apiMessage:''
    };     
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleChange = (e, stateName, type) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(e.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "password":
        if (this.verifyLength(e.target.value, 6)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      
        default:
        break;
      }
      this.setState({
        [e.target.name]: e.target.value
    });
    
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  
  userLogin = e => {
    e.preventDefault();
    if( this.state.email!=='' && this.state.password!=='' && this.state.email!==undefined && this.state.password!==undefined ){
      if(!this.verifyEmail(this.state.email)){
        this.setState({emailState:'has-danger'});
      }else if(!this.verifyLength(this.state.password,6)){
        this.setState({passwordState:'has-danger'});
      }else{
        this.setState({isLoading:true});
        this.props.login(this.state);
        this.setState({errorMsg:true,isLoading:false});  
      }
         
    }else{
       
        this.setState({
          
          emailState:'has-danger',
          passwordState:'has-danger'
        });
    }     
  }
  render() {
    if( this.state.isLogin || localStorage.getItem('userData') ){
      let authInfo = this.props.user; 

      if(authInfo!==undefined && (Number(authInfo.access_id) === 1 || Number(authInfo.access_id) ===2)){
         return (<Redirect to='/admin/dashboard' />)
      }
      /* else if(authInfo!==undefined && Number(authInfo.access_id) ===3 ){
        return (<Redirect to='/owner/owner-dashboard' />)
     } */
     else if(authInfo!==undefined && Number(authInfo.access_id) ===3 || authInfo!==undefined && Number(authInfo.access_id) ===4 ){
      return (<Redirect to='/intake/dashboard' />)
   }
     {
      return (<Redirect to='/home' />)
   }
    }else{
      if(this.state.isLoading){
        return showLoader();
      }else{
        let { emailState,passwordState } =this.state;
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                      {this.props.apiMessage!=='' && this.props.apiMessage!==undefined &&
                      <p>{this.props.apiMessage} </p>}
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                  <FormGroup className={`has-label ${emailState}`}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" name="email" type="text" onChange={(e) =>
                          this.handleChange(e, "email", "email")
                        } value={this.state.email}/>
                        
                    </InputGroup>
                    {this.state.emailState === "has-danger" ? (
                        <label className="error"><br />
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </FormGroup>  
                    <FormGroup className={`has-label ${passwordState}`}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        name="password"
                        value={this.state.password}
                        onChange={(e) =>
                          this.handleChange(e, "password", "password")
                        }
                      />
                      
                    </InputGroup>
                    {this.state.passwordState === "has-danger" ? (
                        <label className="error">The password must be at least 6 characters.</label>
                      ) : null}
                    </FormGroup>
                   
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="info"
                      onClick={this.userLogin}
                      type="submit"
                    >
                      Login
                    </Button>
                    <Link  to="/auth/forgot-password"> 
                      <span className="btn-label text-info">
                        Forgot Password?
                      </span>
                    </Link>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        {this.state.errorMsg.type!=='' && this.state.alert!==null &&
        <ReactBSAlert
          type={this.state.errorMsg.type}
          style={{ display: "block", marginTop: "-100px" }}
          title=""
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {this.state.errorMsg.msg}
        </ReactBSAlert> 
        }  
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/img_kj.png")})`,
          }}
        />
      </div>
    );
        }
        }
  }
}

function mapState(state) {
  const { isLogin, user } = state.AuthReducer;
  const { apiMessage } = state.AlertReducer;
  return { isLogin,user,apiMessage };
}


const actionCreators = {
  login: AuthAction.login,
};

export default connect(mapState, actionCreators)(Login);
