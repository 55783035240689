
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import { SendGetData, showLoader } from '../../service/fetchData';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Pagination from '../../components/Common/Pagination';

class Intake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecord:0,
            per_page:0,
            offset:0,
            pageNo:1,
            page:0,
            pagination:{},
            emailList:'',
            isLoading:true,
            alert:null,
            intakeList:'',
            keyword:''
    }
  }
  getAllIntakes = (page,keyword) => {
    if(page===undefined || page===null){page=1;}
    if(keyword===undefined || keyword===null){keyword='';}
    SendGetData('/get-all-intakes?page='+page+'&s='+keyword, {})
      .then(result => {
        let totalRecord = result.data.pagination.total;
          let per_page = result.data.pagination.per_page;
          totalRecord = Math.ceil(totalRecord/per_page);
          let page = ( totalRecord < result.data.pagination.current_page ) ? totalRecord : result.data.pagination.current_page - 1;
          this.setState({
            intakeList: result.data.data,
            isLoading: false,
            pagination:result.data.pagination,
            page:page,
            totalRecord:totalRecord
          })
        

      })
  }

  handlePageClick = data => {
    let offset = data.selected + 1;
    this.setState({isLoading:true})
    localStorage.setItem('intake_pagination',offset);
    this.getAllIntakes(offset,this.state.keyword);

    
    }

    findSearch =(e)=>{
      e.preventDefault();
      localStorage.setItem('intake_keyword',this.state.keyword);   
      this.setState({isLoading:true})
      this.getAllIntakes( 1,this.state.keyword);
    }

    ResetFn=()=>{
      localStorage.setItem('intake_keyword','');   
      this.setState({keyword:'',isLoading:true},()=>{
        this.getAllIntakes( 1,'');
      });
    }

  warningWithConfirmMessage = (id) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure you want to delete?"
          onConfirm={() => this.delAppConfig(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          
        </ReactBSAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleChange = e => {

    this.setState({
      [e.target.name]: e.target.value
    });
} 


  componentDidMount() {

    this.setState({keyword:localStorage.getItem('intake_keyword'),last_page:localStorage.getItem('intake_pagination')},()=>{
      this.getAllIntakes(this.state.last_page,this.state.keyword);
    });
    
    
    if(localStorage.getItem('userData') && localStorage.getItem('userData')!==undefined){
        let userData=JSON.parse(localStorage.getItem('userData'));
        let user_access_id=Number(userData.access_id);
        let edit_url='/admin/edit-intake';
        let add_url='/admin/add-intake';
        let view_url='/admin/view-intake';
        
        if(user_access_id===3 || user_access_id===4){
          edit_url='/intake/edit-intake';
          add_url='/intake/add-intake';
          view_url='/intake/view-intake';
        }
        this.setState({
          edit_url:edit_url,
          add_url:add_url,
          view_url:view_url,
          access_id:user_access_id
        })
    }
  }
  render() {
    let b_list = '';
    let edit_url=this.state.edit_url;
    let view_url=this.state.view_url;
    let add_url=this.state.add_url;
    let totalRecord=this.state.totalRecord;
    if (this.state.isLoading) {
      b_list = showLoader();
    } else {

      const { intakeList } = this.state;
      if(intakeList!=='' && intakeList!==undefined){
        b_list = Object.keys(intakeList).map((page, k) => {
          let t_id="tooltip_st_"+ intakeList[k].id;
            let editURL = edit_url+"/" + intakeList[k].id;
            let viewURL = view_url+"/" + intakeList[k].id;
            let u_key = 'app-' + intakeList[k].id;
            let active_class = 'btn-sm btn-round btn-icon btn btn-success';
            let st='Active';
            if (Number(intakeList[k].status) === 0) {st='Deleted';
              active_class = 'btn-sm btn-round btn-icon btn btn-youtube';}
            if (Number(intakeList[k].status) === 1) {st='Declined';
            active_class = 'btn-sm btn-round btn-icon btn btn-secondary';}
          
            if (Number(intakeList[k].status) === 2) {st='Pending';
              active_class = 'btn-sm btn-round btn-icon btn btn-warning';}
            
            if (Number(intakeList[k].status) === 3) {st='Approved';
            active_class = 'btn-sm btn-round btn-icon btn btn-success';}
            if (Number(intakeList[k].status) === 4) {st='Drated';
            active_class = 'btn-sm btn-round btn-icon btn btn-primary';
          }
            return (
              <tr key={u_key}>
                <td>{intakeList[k].id}</td>
                <td>{intakeList[k].patient_name}</td>
                <td>{intakeList[k].mrn}</td>
                <td>{intakeList[k].received_date}</td>
                <td>{intakeList[k].intake_user}</td>
                <td>
                        <span id={t_id}>{st}
                        </span>
                        {/* <UncontrolledTooltip
                            delay={0}
                            target={t_id}
                          >
                            {st}
                          </UncontrolledTooltip> */}
                        </td>
                <td>
                  {this.state.access_id <= 3 && 
                    <Link className="page_editIcon btn-sm btn-icon btn-primary mr-3" to={editURL}><i className="fa fa-edit" aria-hidden="true"></i></Link>
                  }
                  
                <Link className="page_editIcon btn-sm btn-icon btn-primary mr-3" to={viewURL}><i className="fa fa-eye" aria-hidden="true"></i></Link>
                {/* <span className="btn-sm btn-icon btn-primary" onClick={()=>this.warningWithConfirmMessage(intakeList[k].id)}><i className="fa fa-times" ></i></span> */}
                </td>
    
              </tr>
            )
          })
      }
      
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              {this.state.alert!==null && 
                <p>{this.state.alert}</p>
              }
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="10">
                    <CardTitle tag="h4">Intakes</CardTitle>
                    </Col>
                  <Col md="2" className="text-right">
                    <Link className="btn btn-info" to={add_url}> 
                      <span className="btn-label">
                        <i className="nc-icon nc-simple-add" />
                      </span>
                      Add
                    </Link>
                    </Col>
                    </Row>
                    <form onSubmit={this.findSearch}>
                    <Row>
                    <Col md="4">
                      <label>Keyword</label>
                      <input type="text" name="keyword" placeholder="Search" className="form-control" onChange={this.handleChange}  value={this.state.keyword} />

                    </Col>
                    <Col md="1" className="mr-5">
                      <Button color="primary" type="submit" onClick={this.findSearch}>
                        Search
                      </Button>
                    </Col>
                    <Col md="1">
                      <Button color="primary" onClick={this.ResetFn}>
                        Reset
                      </Button>
                    </Col>
                    </Row>
                    </form>
                </CardHeader>
                <CardBody>
                  
                  <Table responsive striped>
                    <thead className="text-primary">
                      <tr>
                        <th>ID</th>
                        <th>Patient Name</th>
                        <th>MRN</th>
                        <th>Received Date</th>
                        <th>Intake User</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                     {b_list}
                    </tbody>
                  </Table>
                  {(totalRecord>0 && <Pagination total={totalRecord} click={this.handlePageClick} pageNumber={this.state.page} />)} 
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}



export default Intake;
