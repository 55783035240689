import React, { Component } from 'react';
import {
    Button,
    FormGroup,
    Row,
    Col,
  } from "reactstrap";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { SendPostData, SendGetData, showErroMsg,showLoader } from '../../service/fetchData';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";


class ViewIntake extends Component{
    constructor(props) {
        super(props);
        this.state = {
            intakeData:{},
            create_message:'',
            isLoading:true,
            alert:'',
            remarks_not_intake:''
        }
        
      }
      
    getIntakewithID=(id)=>{

      SendGetData('/get-intakewithid/'+id)
      .then(result => {
        if(result){
         this.setState({
          intakeData: result.data,
          isLoading: false,
         })  
        }
      })
      .catch(function (error) {
         console.log(error);
      });
      }

      handleDecline = () => {
        this.setState({
          alert: (
            <ReactBSAlert
              input
              showCancel
              style={{ display: "block", marginTop: "-100px" }}
              title="Reamarks, if not take"
              onConfirm={(e) => this.inputConfirmAlert(e)}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              btnSize=""
            />
          ),
        });
      };
      inputConfirmAlert = (e) => {
        let updateData = Object.assign({}, this.state.intakeData, { 'remarks_not_intake': e });
        this.setState({
            intakeData: updateData
          },()=>{
               this.handleFormSubmit(1);
          })
      };
    handleFormSubmit =(status_id)=>{
        let updateData = Object.assign({}, this.state.intakeData, { 'status': status_id });
        this.setState({
            intakeData: updateData
          },()=>{
            SendPostData('/change-status', this.state.intakeData)
            .then((response) => {
                this.setState({isLoading:false});
                this.hideAlert();
                if(response.data.success){
                  let data_msg=["Intake saved successfully!"];
                  let errorItems = showErroMsg({"message":[data_msg]}) 
                    this.setState({ errorMsg:errorItems});
                    
                }else{
                    let errorItems = showErroMsg(response.data.data)
                    this.setState({
                        errorMsg: errorItems,alert:1
                                          
                    }); 
                }
            })
            .catch(error => {
                this.setState({
                    errorMsg: {type:'error',msg:error},alert:1
                });                        
            }); 
          });
      
      
    }

    handleApprove = () => {
        this.setState({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => this.handleFormSubmit(3)}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              confirmBtnText="Confirm"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to approve this record?
            </ReactBSAlert>
          ),
        });
      };

      hideAlert = () => {
        this.setState({
          alert: null,
        });
      };

      componentDidMount(){
        let id = (this.props.match.params.id) ? this.props.match.params.id : 0;
        if(id>0){
          this.getIntakewithID(id);
        }else{
          this.setState({isLoading:false})
        }
        if(localStorage.getItem('userData') && localStorage.getItem('userData')!==undefined){
          let userData=JSON.parse(localStorage.getItem('userData'));
          let user_access_id=Number(userData.access_id);
          let cancel_url='/admin/intake';
          if(user_access_id===3 || user_access_id===4){
            cancel_url='/intake/intake';
          }
          this.setState({
            cancel_url:cancel_url,
            user_access_id:user_access_id
          })
      }
        
      }
    render(){
      let intakeData=this.state.intakeData;
      let cancel_url=this.state.cancel_url;
      const status_options=["Deleted","Declined","Pending","Approved","Drafted"];
      

        if( this.state.isLoading ){
            return showLoader();
        }else{
        return(
            <>
        <div className="content">
        {this.state.alert}
          <Row>
          {this.state.errorMsg!='' && 
                        <Col md="12">{this.state.errorMsg}</Col>}
            <Col md="6">
            <FormGroup className={`has-label ${this.state.patient_nameState}`}>
                  <label>Patient Name : {intakeData.patient_name}</label>
              </FormGroup>
              <FormGroup className={`has-label`}>
                      <label>Received Date : {intakeData.received_date === null ? '' : intakeData.received_date}</label>
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                  <label> Order Type : {intakeData.order_type}</label>
                  
        
              </FormGroup>
              <FormGroup className={`has-label`}>
                      <label>Accepted Time : {intakeData.accepted_time === null ? '' : intakeData.accepted_time}</label>
                      
                  </FormGroup>    
              

                  <FormGroup className={`has-label`}>
                      <label>Placed Time : {intakeData.placed_time === null ? '' : intakeData.placed_time}</label>
                      
                  </FormGroup> 
                  <FormGroup className={`has-label`}>
                      <label>Intake Done Time(PST) : {intakeData.intake_done_time === null ? '' : intakeData.intake_done_time}</label>
                      
                  </FormGroup>   
                  
              <FormGroup className={`has-label `}>
                  <label>Area : {intakeData.area}</label>
                  
              </FormGroup>
              <FormGroup className={`has-label `}>
                  <label>SOC Staffing : {intakeData.soc_staffing} </label>
                  
              </FormGroup>
              <FormGroup className={`has-label `}>
                    <label>Other Comments : {intakeData.other_comments}</label>
                    
                </FormGroup>
                <FormGroup className={`has-label`}>
                  <label>Status : {status_options[Number(intakeData.status)]}</label>
                  
                 
              </FormGroup>
            </Col>

            <Col md="6">
            <FormGroup className={`has-label ${this.state.mrnState}`}>
                  <label>MRN : {intakeData.mrn}</label>
                  
              </FormGroup>
            <FormGroup className={`has-label `}>
                  <label>Source : {intakeData.source}</label>
              </FormGroup>

              <FormGroup className={`has-label`}>
                      <label>Accepted Date : {intakeData.accepted_date === null ? '' : intakeData.accepted_date}</label>
                     
                  </FormGroup>
                  <FormGroup className={`has-label`}>
                      <label>Placed Date : {intakeData.placed_date === null ? '' : intakeData.placed_date}</label>
                     
                  </FormGroup>
              
                  <FormGroup className={`has-label`}>
                      <label>Posted in Referral Group Time : {intakeData.posted_in_referral_group_time === null ? '' : intakeData.posted_in_referral_group_time}</label>
                      
                  </FormGroup>   
                  <FormGroup className={`has-label `}>
                    <label>Remarks, if not intake : {intakeData.remarks_not_intake}</label>
                    
                </FormGroup>
                
              <FormGroup className={`has-label `}>
                    <label>Order Details : {intakeData.order_details}</label>
                   
                </FormGroup>
                <FormGroup className={`has-label `}>
                  <label>Welcome call status : {intakeData.welcome_call_status}</label>
                  
              </FormGroup>
              <FormGroup className={`has-label `}>
                    <label>Patient Remarks : {intakeData.remarks}</label>
                   
                </FormGroup>

            </Col>
            <Col md="12" align="center">
              {this.state.user_access_id===4 &&
                <><Button className="btn btn-info" type="submit" onClick={this.handleApprove}>Approve</Button>
                <Button className="btn btn-info" type="submit" onClick={this.handleDecline}>Decline</Button></>
              }
              
                <Link className="btn btn-primary ml-3" to={cancel_url}> 
                  Close    
                </Link>
            </Col>
            
          </Row>
        </div>
      </>


        );
        }
    }
}
export default ViewIntake;