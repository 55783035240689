
import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { connect } from 'react-redux';
import { AuthAction } from '../../actions';
import HeaderInner from "../../components/Header/HeaderInner";
import Footer from "../../components/Footer/Footer";
import { showLoader, SendPostData,authCheckModule } from "service/fetchData";
import ImageCropper from "../components/ImageCropper";
import ReactBSAlert from "react-bootstrap-sweetalert";
import avatar_img from "../../assets/img/avatar_img.png"
import {UploadsURL} from "../../constants/Config";
import ChangePassword from '../../views/pages/ChangePassword';



class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      password: "",
      cancel_url: "",
      name: "",
      phone: "",
      avatar:"",
      email:"",
      avatar_img:'',
      errorMsg:{},
      alert:null,
      isLoading:true,
      isEdit:0,
      password_confirmation: "",
      profileInfos:{}
    };
  }

  componentDidMount() {
    if(localStorage.getItem('userData') && localStorage.getItem('userData')!==undefined){
      console.log('dgvfgvg');
      this.props.profileInfo(0);
    }else{
      console.log('dgvfgvg258963');
      window.location.href='/auth/login';
    }
    
    
    
}
  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleChange = (e, stateName, type,length=0) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(e.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "password":
        if (this.verifyLength(e.target.value, 6)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
        case "number":
        if (this.verifyNumber(e.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(e.target.value,length)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      
        default:
        break;
      }
      let updateData = Object.assign({}, this.state.profileInfos, { [e.target.name]: e.target.value })
        this.setState({
          profileInfos: updateData
        });
      
      
      
  }




  // function that returns true if value is email, false otherwise
  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if two strings are equal
  
  verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  dataSubmit = e => {
    e.preventDefault();
    this.props.UserprofileUpdate(JSON.stringify(this.state.profileInfos));
  }

  htmlAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Select your avatar"
          onConfirm={() => this.updatePicture()}
          onCancel={() => this.hideAlert()}
          btnSize=""
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Submit"
          cancelBtnText="Cancel"
        >
          <ImageCropper cropImg={this.cropImgs} ImgName='avatar' />
        </ReactBSAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  }
  
  updatePicture = (e) => {
    let data={
      id:this.state.profileInfos.id,
      avatar:this.state.avatar
    }
    SendPostData('/photo-update', data)
    .then(response => {
      let updateUserData = Object.assign({}, this.state.profileInfos, { avatar: response.data.avatar })
      this.setState({
          profileInfos: updateUserData,
          avatar_img:response.data.avatar,
          alert:null
      });
    })
     
    /* e.preventDefault(); */
    /* this.props.profilePictureUpdate(JSON.stringify(this.state));
    this.closeModal(); */
  }
  cropImgs = (img, imgName) => {
    //if(img){
    this.setState({
        avatar: img
    });
    //}
  }

  render() {
    authCheckModule();
    let profileInfos=this.state.profileInfos;
    let {emailState,nameState,phoneState}=this.state;
    if(this.state.isLoading || profileInfos===undefined){
      return showLoader();
    }else{
      let avatar=avatar_img;
      if(profileInfos.avatar!==null){
        avatar=UploadsURL+profileInfos.avatar;
      }
    return (
      <>
      <div className="wrapper">
        <HeaderInner title="Linking Voice" desc=""/>
        <div className="section">
        <div className="content">
          <Row>
            <Col md="4">
              <Card className="card-user">
                <div className="image">
                  
                </div>
                <CardBody>
                  <div className="author">
                  <img
                        alt="..."
                        className="avatar border-gray"
                        src={avatar}
                      />
                    
                  </div>
                  <p className="description text-center">
                  <Button
                      className="btn-round"
                      color="default"
                      onClick={this.htmlAlert}
                      outline
                    >
                      Change Avatar
                    </Button>
                    
                  </p>
                  {this.state.alert}
                </CardBody>
                
              </Card>
              
            </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                  {this.props.apiMessage}
                </CardHeader>
                <CardBody>
                  <Form>
                  <FormGroup className={`has-label ${nameState}`}>
                      <label>Name *</label>
                      <Input
                        id="Name"
                        name="name"
                        type="text"
                        autoComplete="off"
                        value={profileInfos.name}
                        onChange={(e) =>
                          this.handleChange(e, "name", "length", 2)
                        }
                        maxLength={100}
                      />
                      {this.state.nameState === "has-danger" ? (
                        <label className="error">The name must be between 2 and 100 characters.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${emailState}`}>
                      <label>Email Address *</label>
                      <Input
                      id="email"
                        name="email"
                        type="email"
                        value={profileInfos.email}
                        onChange={(e) =>
                          this.handleChange(e, "email", "email")
                        } 
                        disabled={true}
                      />
                      {this.state.emailState === "has-danger" ? (
                        <label className="error"><br />
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </FormGroup>
                    
                    <FormGroup
                      className={`has-label ${phoneState}`}
                    >
                      <label>Phone Number</label>
                      <Input
                        id="phone"
                        name="phone"
                        type="text"
                        autoComplete="off"
                        value={profileInfos.phone}
                        onChange={(e) =>
                          this.handleChange(e, "phone", "number")
                        }
                      />
                      {this.state.phoneState ===
                      "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                    
                    
                    <div className="category form-category">
                      * Required fields
                    </div>
                    <Button color="primary" onClick={this.dataSubmit}>
                      Update
                    </Button>
                    
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={12}>
            <ChangePassword user_id={profileInfos.id} />
            </Col>
          </Row>
        </div>
        </div>
        <Footer />
      </div>
    </>
        
    );
                      }
  }
}

function mapState(state) {
  let { profileInfos, isLogin, isLoading } = state.AuthReducer;
  const { apiMessage } = state.AlertReducer;
  return { profileInfos, isLogin, isLoading, apiMessage };
}

const actionCreators = {
  profileInfo: AuthAction.profileInfo,
  profilePictureUpdate: AuthAction.profilePictureUpdate,
  UserprofileUpdate: AuthAction.UserprofileUpdate
};

export default connect(mapState, actionCreators)(UserProfile);
