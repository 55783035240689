import React, { Component } from 'react';
import {
    Button,
    FormGroup,
    Input,
    Row,
    Col,
  } from "reactstrap";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { SendPostData, SendGetData, showErroMsg,showLoader } from '../../service/fetchData';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import ReactDatetime from "react-datetime";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";


class AddIntake extends Component{
    constructor(props) {
        super(props);
        this.state = {
            intakeData:{},
            create_message:'',
            isLoading:true
        }
        
      }
      handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
       
    }
    hideAlert = () => {
      this.setState({
        alert: null,
      });
    };

    handleAlert = (e,status_id) => {
      let msg="Are you sure you want to delete it?";
      if(status_id===2){
        msg="Are you sure you want to submit for approval?";
      }
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={msg}
            onConfirm={() => this.handleFormSubmit(e,status_id)}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Confirm"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          />
            
        ),
      });
    };



    handleCheckClick = (e) => {
      this.setState({
        [e.target.name]: e.target.value
    });
    }

    onEditorChange= (e) => {
      this.setState({email_content: e.target.value})
  }
    onChange=(e)=>{
        let files=e.target.files;
        
      let reader= new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload=(e)=>{ 
        this.setState({
          featured_img: e.target.result,
          page_img:e.target.result
      });
      }
    }

    handleChange = (e, stateName, type='',length=0,c=0) => {
      switch (type) {
        case "email":
          if (this.verifyEmail(e.target.value)) {
            this.setState({ [stateName + "State"]: "has-success" });
          } else {
            this.setState({ [stateName + "State"]: "has-danger" });
          }
          break;
        case "password":
          if (this.verifyLength(e.target.value, 6)) {
            this.setState({ [stateName + "State"]: "has-success" });
          } else {
            this.setState({ [stateName + "State"]: "has-danger" });
          }
          break;
          case "number":
          if (this.verifyNumber(e.target.value)) {
            this.setState({ [stateName + "State"]: "has-success" });
          } else {
            this.setState({ [stateName + "State"]: "has-danger" });
          }
          break;
        case "length":
          if (this.verifyLength(e.target.value,length)) {
            this.setState({ [stateName + "State"]: "has-success" });
          } else {
            this.setState({ [stateName + "State"]: "has-danger" });
          }
          break;
   
        
          default:
          break;
        }
        if(c>0){
          if(type!=='date'){
            let updateData = Object.assign({}, this.state.legal_edit, { [e.target.name]: e.target.value })
            this.setState({
              legal_edit: updateData
            });
          }else{
            let updateData = Object.assign({}, this.state.legal_edit, { [stateName]: e.format("YYYY-MM-DD") })
            this.setState({
              legal_edit: updateData
            });
          }
  
        }else{
          if(type=='time'){
            let updateData = Object.assign({}, this.state.intakeData, { [stateName]: e.format("HH:mm A") })
            this.setState({
              intakeData: updateData
            });
            
          }else if(type=='date'){
            let updateData = Object.assign({}, this.state.intakeData, { [stateName]: e.format("YYYY-MM-DD") })
            this.setState({
              intakeData: updateData
            });
          }else if(type=='datetime'){
            let updateData = Object.assign({}, this.state.intakeData, { [stateName]: e.format("YYYY-MM-DD HH:mm A") })
            this.setState({
              intakeData: updateData
            });
          }else{
            let updateData = Object.assign({}, this.state.intakeData, { [e.target.name]: e.target.value })
            this.setState({
              intakeData: updateData
            });
          }
        }
        
        
        
        
    }

    getIntakewithID=(id)=>{

      SendGetData('/get-intakewithid/'+id)
      .then(result => {
        if(result){
         this.setState({
          intakeData: result.data,
          isLoading: false,
         })  
        }
      })
      .catch(function (error) {
         console.log(error);
      });
      }

    verifyLength = (value, length) => {
      if (value.length >= length) {
        return true;
      }
      return false;
    };
  
    verifyNumber = (value) => {
      var numberRex = new RegExp("^[0-9]+$");
      if (numberRex.test(value)) {
        return true;
      }
      return false;
    };

    handleFormSubmit =(e,status_id)=>{
      let intakeData=this.state.intakeData;
      if( intakeData.received_date &&
        intakeData.patient_name &&
        intakeData.mrn   ){
        let updateData = Object.assign({}, this.state.intakeData, { status: status_id });

        this.setState({
          intakeData: updateData,
          alert:'',
          isLoading:true
        },()=>{
          SendPostData('/save-intakes', this.state.intakeData)
          .then((response) => {
              this.setState({isLoading:false});
              if(response.data.success){
                let data_msg=["Intake saved successfully!"];
                let errorItems = showErroMsg({"message":[data_msg]}) 
                  this.setState({ errorMsg:errorItems});
                  
              }else{
                  let errorItems = showErroMsg(response.data.data)
                  this.setState({
                      errorMsg: errorItems,
                      isLoading:false
                                        
                  }); 
              }
          })
          .catch(error => {
              this.setState({
                errorMsg: error,
                isLoading:false
              });                        
          }); 
        });
          
            
                      
      }else{
         /*  let errorItems = showErroMsg({"message":["Fields marked in red asterisks are required."]}) */
          this.setState({ isLoading:false})
          if(intakeData.received_date==='' || intakeData.received_date===undefined){
            this.setState({received_dateState:'has-danger'})
          }
          if(intakeData.patient_name==='' || intakeData.patient_name===undefined){
            this.setState({patient_nameState:'has-danger'})
          }
          if(intakeData.mrn==='' || intakeData.mrn===undefined){
            this.setState({mrnState:'has-danger'})
          }
          
          /* this.setState({
              errorMsg: {
                  type:'alert alert-danger',
                  msg:'All fields are mandatory'
              }                    
          }); */
      }
      
    }

    /* handleFormSubmit = e => {
        e.preventDefault(); 
        this.setState({isLoading: true});      
        SendPostData('/save-intakes',this.state)
          .then(result => {
              if(result.data.success!==false){
                
                window.location.href = "/admin/emails-content";
              }else{
                let errorItems = showErroMsg(result.data.data);
                this.setState({
                  create_message:errorItems,
                  isLoading: false
                });
              }
            
          })
          .catch(error => this.setState({ error: error.message }));
      }; */
      componentDidMount(){
        let id = (this.props.match.params.id) ? this.props.match.params.id : 0;
        if(id>0){
          this.getIntakewithID(id);
        }else{
          this.setState({isLoading:false})
        }
        if(localStorage.getItem('userData') && localStorage.getItem('userData')!==undefined){
          let userData=JSON.parse(localStorage.getItem('userData'));
          let user_access_id=Number(userData.access_id);
          let cancel_url='/admin/intake';
          if(user_access_id===3){
            cancel_url='/intake/intake';
          }
          this.setState({
            cancel_url:cancel_url,
          })
      }
        
      }
    render(){
      let intakeData=this.state.intakeData;
      let cancel_url=this.state.cancel_url;
      const status_options=[
        { value: 2, label: "Pending" },
        { value: 3, label: "Approved" },
        { value: 4, label: "Drafted" },
        { value: 1, label: "Declined" },
        { value: 0, label: "Deleted" },
      ];

      const source_options=[
        {value:'Allscript', label:'ALLSCRIPT'},
        {value:'Fax', label:'FAX'},
      ];

      const ordertype_options=[
        {value:'SOC', label:'SOC'},
        {value:'ROC', label:'ROC'},
        {value:'SUPP', label:'SUPP'},
        {value:'ADD ORDER', label:'ADD ORDER'},
        {value:'WC ORDER', label:'WC ORDER'},
        {value:'F2F', label:'F2F'},
        {value:'UPDATED', label:'UPDATED'},
      ];
      const area_options=[
        {value:'CC', label:'CC'},
        {value:'CVY', label:'CVY'},
        {value:'HAY', label:'HAY'},
        {value:'OAK', label:'OAK'},
        {value:'SC', label:'SC'},
        {value:'NGS', label:'NGS'},
        {value:'NON-KAISER', label:'NON-KAISER'},
      ];
      const soc_options=[
        {value:'Staffed', label:'STAFFED'},
        {value:'Not Staffed', label:'NOT STAFFED'},
        {value:'Partially Staffed', label:'PARTIALLY STAFFED'},
        {value:'RE-ASSIGNED', label:'RE-ASSIGNED'},
       
      ];
      const welcome_options=[
        {value:'DONE', label:'DONE'},
        {value:'NOT DONE', label:'NOT DONE'},
        {value:'LVM', label:'LVM'},
       
      ];

        if( this.state.isLoading ){
            return showLoader();
        }else{
        return(
            <>
        <div className="content">
          <Row>
          {this.state.errorMsg!='' && 
            <Col md="12">{this.state.errorMsg}</Col>}
            <Col md="12">{this.state.alert}</Col>
            <Col md="6">
            <FormGroup className={`has-label ${this.state.patient_nameState}`}>
                  <label>Patient Name *</label>
                  
                  <Input
                  id="patient_name"
                  name="patient_name"
                  type="text"
                  autoComplete="off"
                  value={intakeData.patient_name}
                  onChange={(e) =>
                      this.handleChange(e, "patient_name", "length", 2)
                  }
                  maxLength={100}
                  />
                  {this.state.patient_nameState === "has-danger" ? (
                  <label className="error">Patient Name is required</label>
                  ) : null}
              </FormGroup>
              <FormGroup className={`has-label ${this.state.received_dateState}`}>
                      <label>Received Date*</label>
                      <ReactDatetime
                        timeFormat={false}
                        dateFormat='YYYY-MM-DD'
                        name="received_date"
                        onChange={(e) =>
                          this.handleChange(e, "received_date","date")
                      }
                      inputProps={{
                        className: "form-control",
                        placeholder: "Please select date",
                      }}
                      value={intakeData.received_date === null ? '' : intakeData.received_date}
                    />
                     {this.state.received_dateState === "has-danger" ? (
                  <label className="error">Received Date is required</label>
                  ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label ${this.state.address1State}`}>
                  <label> Order Type</label>
                  
                  
                  <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="order_type"
                      value={ordertype_options.filter(function(option) {
                        return option.value === intakeData.order_type;
                      })}
                      onChange={(value) =>{
                        let updateUserData = Object.assign({}, this.state.intakeData, { order_type: value.value })
                        this.setState({ intakeData: updateUserData })
                      }
                      }
                      options={ordertype_options}
                      placeholder="Please select order type"
                    />
                 
              </FormGroup>
              <FormGroup className={`has-label`}>
                      <label>Accepted Time</label>
                      <ReactDatetime
                        dateFormat={false}
                        timeFormat='HH:mm A'
                        name="accepted_time"
                        onChange={(e) =>
                          this.handleChange(e, "accepted_time","time")
                      }
                      inputProps={{
                        className: "form-control",
                        placeholder: "Please select time",
                      }}
                      value={intakeData.accepted_time === null ? '' : intakeData.accepted_time}
                    />
                  </FormGroup>    
              

                  <FormGroup className={`has-label`}>
                      <label>Placed Time</label>
                      <ReactDatetime
                        dateFormat={false}
                        timeFormat='HH:mm A'
                        name="placed_time"
                        onChange={(e) =>
                          this.handleChange(e, "placed_time","time")
                      }
                      inputProps={{
                        className: "form-control",
                        placeholder: "Please select time",
                      }}
                      value={intakeData.placed_time === null ? '' : intakeData.placed_time}
                    />
                  </FormGroup> 
                  <FormGroup className={`has-label`}>
                      <label>Intake Done Time(PST)</label>
                      <ReactDatetime
                        dateFormat={false}
                        timeFormat='HH:mm A'
                        name="intake_done_time"
                        onChange={(e) =>
                          this.handleChange(e, "intake_done_time","time")
                      }
                      inputProps={{
                        className: "form-control",
                        placeholder: "Please select time",
                      }}
                      value={intakeData.intake_done_time === null ? '' : intakeData.intake_done_time}
                    />
                  </FormGroup>   
                  
              <FormGroup className={`has-label `}>
                  <label>Area </label>
                  
                  <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="area"
                      value={area_options.filter(function(option) {
                        return option.value === intakeData.area;
                      })}
                      onChange={(value) =>{
                        let updateUserData = Object.assign({}, this.state.intakeData, { area: value.value })
                        this.setState({ intakeData: updateUserData })
                      }
                      }
                      options={area_options}
                      placeholder="Please select area"
                    />
                  
              </FormGroup>
              <FormGroup className={`has-label `}>
                  <label>SOC Staffing </label>
                  
                  
                  <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="soc_staffing"
                      value={soc_options.filter(function(option) {
                        return option.value === intakeData.soc_staffing;
                      })}
                      onChange={(value) =>{
                        let updateUserData = Object.assign({}, this.state.intakeData, { soc_staffing: value.value })
                        this.setState({ intakeData: updateUserData })
                      }
                      }
                      options={soc_options}
                      placeholder="Please select SOC staffing"
                    />
                  
              </FormGroup>
              <FormGroup className={`has-label `}>
                    <label>Other Comments</label>
                    <textarea rows="5" name="other_comments" className="form-control" onChange={(e) =>
                        this.handleChange(e, "other_comments")
                    } value={intakeData.other_comments}></textarea>
                    
                </FormGroup>
               {/*  <FormGroup className={`has-label`}>
                  <label>Status</label>
                  
                  {/* <Input
                  id="status"
                  name="status"
                  type="text"
                  autoComplete="off"
                  value={intakeData.status}
                  onChange={(e) =>
                      this.handleChange(e, "status", "length", 2)
                  }
                  maxLength={100}
                  /> }
                  <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="status"
                      value={status_options.filter(function(option) {
                        return option.value === Number(intakeData.status);
                      })}
                      onChange={(value) =>{
                        let updateUserData = Object.assign({}, this.state.intakeData, { status: value.value })
                        this.setState({ intakeData: updateUserData })
                      }
                      }
                      options={status_options}
                      placeholder="Please select status"
                    />
                  
              </FormGroup> */}
            </Col>

            <Col md="6">
            <FormGroup className={`has-label ${this.state.mrnState}`}>
                  <label>MRN *</label>
                  
                  <Input
                  id="mrn"
                  name="mrn"
                  type="text"
                  autoComplete="off"
                  value={intakeData.mrn}
                  onChange={(e) =>
                      this.handleChange(e, "mrn", "length", 2)
                  }
                  maxLength={100}
                  />
                  {this.state.mrnState === "has-danger" ? (
                  <label className="error">MRN is required</label>
                  ) : null}
              </FormGroup>
            <FormGroup className={`has-label `}>
                  <label>Source </label>
                  
                  <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="source"
                      value={source_options.filter(function(option) {
                        return option.value === intakeData.source;
                      })}
                      onChange={(value) =>{
                        let updateUserData = Object.assign({}, this.state.intakeData, { source: value.value })
                        this.setState({ intakeData: updateUserData })
                      }
                      }
                      options={source_options}
                      placeholder="Please select source"
                    />

                      {/* <div className="form-check-radio">
                          <Label check>
                            <Input
                              defaultValue="ALLSCRIPT"
                              id="source_radio1"
                              name="source"
                              type="radio"
                            />
                            ALLSCRIPT <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio">
                          <Label check>
                            <Input
                              defaultChecked
                              defaultValue="FAX"
                              id="source_radio2"
                              name="source"
                              type="radio"
                            />
                            FAX <span className="form-check-sign" />
                          </Label>
                        </div> */}
                  
              </FormGroup>

              <FormGroup className={`has-label`}>
                      <label>Accepted Date</label>
                      <ReactDatetime
                        timeFormat={false}
                        dateFormat='YYYY-MM-DD'
                        name="accepted_date"
                        onChange={(e) =>
                          this.handleChange(e, "accepted_date","date")
                      }
                      inputProps={{
                        className: "form-control",
                        placeholder: "Please select date",
                      }}
                      value={intakeData.accepted_date === null ? '' : intakeData.accepted_date}
                    />
                  </FormGroup>
                  <FormGroup className={`has-label`}>
                      <label>Placed Date</label>
                      <ReactDatetime
                        timeFormat={false}
                        dateFormat='YYYY-MM-DD'
                        name="placed_date"
                        onChange={(e) =>
                          this.handleChange(e, "placed_date","date")
                      }
                      inputProps={{
                        className: "form-control",
                        placeholder: "Please select date",
                      }}
                      value={intakeData.placed_date === null ? '' : intakeData.placed_date}
                    />
                  </FormGroup>
              
                  <FormGroup className={`has-label`}>
                      <label>Posted in Referral Group Time</label>
                      <ReactDatetime
                        dateFormat={false}
                        timeFormat='HH:mm A'
                        name="posted_in_referral_group_time"
                        onChange={(e) =>
                          this.handleChange(e, "posted_in_referral_group_time","time")
                      }
                      inputProps={{
                        className: "form-control",
                        placeholder: "Please select time",
                      }}
                      value={intakeData.posted_in_referral_group_time === null ? '' : intakeData.posted_in_referral_group_time}
                    />
                  </FormGroup>  
                  <FormGroup className={`has-label `}>
                  <label>Welcome call status</label>
                  
                  <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="welcome_call_status"
                      value={welcome_options.filter(function(option) {
                        return option.value === intakeData.welcome_call_status;
                      })}
                      onChange={(value) =>{
                        let updateUserData = Object.assign({}, this.state.intakeData, { welcome_call_status: value.value })
                        this.setState({ intakeData: updateUserData })
                      }
                      }
                      options={welcome_options}
                      placeholder="Please select welcome call status"
                    />
                  
              </FormGroup> 
                  <FormGroup className={`has-label `}>
                    <label>Remarks, if not intake </label>
                    <textarea rows="5" name="remarks_not_intake" className="form-control" onChange={(e) =>
                        this.handleChange(e, "remarks_not_intake")
                    } value={intakeData.remarks_not_intake}></textarea>
                    
                </FormGroup>
                
              <FormGroup className={`has-label `}>
                    <label>Order Details</label>
                    <textarea rows="5" name="order_details" className="form-control" onChange={(e) =>
                        this.handleChange(e, "order_details")
                    } value={intakeData.order_details}></textarea>
                    
                </FormGroup>
                
              <FormGroup className={`has-label `}>
                    <label>Patient Remarks</label>
                    <textarea rows="5" name="remarks" className="form-control" onChange={(e) =>
                        this.handleChange(e, "remarks")
                    } value={intakeData.remarks}></textarea>
                    
                </FormGroup>

            </Col>
            <Col md="12" align="center">
              <Button className="btn btn-info" type="submit" onClick={(e) => this.handleFormSubmit(e,4)}>Save to Draft</Button>
              <Button className="btn btn-info" type="submit" onClick={(e) => this.handleAlert(e,2)}>Submit for Approval</Button>
              <Link className="btn btn-info ml-3" to={cancel_url}> 
                  Cancel    
                </Link>
                <Button className="btn btn-info" type="submit" onClick={(e) => this.handleAlert(e,0)}>Mark Delete</Button>
            </Col>
            
          </Row>
        </div>
      </>


        );
        }
    }
}
export default AddIntake;