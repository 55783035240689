import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  FormGroup
} from "reactstrap";
import {fetchGetData,showErroMsg, showLoader} from "../../service/fetchData";


class ForgotPassword extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email:'',
            alert:null,
            apiMessage:'',
            emailState:'',
        };     
    }
    componentDidMount() {
        document.body.classList.toggle("forgot-password");
    }
    componentWillUnmount() {
        document.body.classList.toggle("forgot-password");
    }

    forgotPasswordSend = e => {
        e.preventDefault();
        this.setState({isLoading:true});
        let registered_email = ( this.state.email ) ? this.state.email: undefined;
        if(this.verifyEmail(registered_email) ){
          fetchGetData(`/auth/forgot-password/${registered_email}`)
          .then(response => {
              this.setState({apiMessage:showErroMsg(response.data.data),isLoading:false });
          });  
        }else{
          this.setState({emailState:'has-danger'})
        }
    }
    verifyEmail = (value) => {
      var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(value)) {
        return true;
      }
      return false;
    };
    handleChange = (e, stateName, type) => {
      switch (type) {
        case "email":
          if (this.verifyEmail(e.target.value)) {
            this.setState({ [stateName + "State"]: "has-success" });
          } else {
            this.setState({ [stateName + "State"]: "has-danger" });
          }
          break;
          default:
          break;
        }
        this.setState({
          [e.target.name]: e.target.value
      });
      }

    render() {
      if(this.state.isLoading){
        return showLoader();
      }else{
      let emailState=this.state.emailState;
        return (
          <div className="forgot-password">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Form action="" className="form" method="">
                    <Card className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <h3 className="header text-center ">Forgot Password</h3>
                          {this.state.apiMessage}
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                      <FormGroup className={`has-label ${emailState}`}>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Email" name="email" type="email" onChange={(e) =>
                          this.handleChange(e, "email", "email")} value={this.state.email}/>
                        </InputGroup>
                        {this.state.emailState === "has-danger" ? (
                        <label className="error"><br />
                          Please enter a valid email address.
                        </label>
                      ) : null}
                       </FormGroup>  
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          className="btn-round mb-3"
                          color="info"
                          onClick={this.forgotPasswordSend}
                        >
                          Submit
                        </Button>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
            
            <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/img_kj.png")})`,
          }}
        />
        </div>
    );
        }
}
}


export default ForgotPassword;