import React, { Component } from 'react';
import { fetchGetData, showErroMsg } from "../../service/fetchData";
import ReactBSAlert from "react-bootstrap-sweetalert";


class VerifyEmail extends Component {

    constructor(props) {
        super(props);
        this.state ={
            isLoading:true,
            errorMsg:{},
            loginLink:false,
            alert:null
        }
    }    

    componentDidMount() {
        let token = this.props.match.params.token ? this.props.match.params.token : 0;
        fetchGetData(`/auth/verify-user/${token}`)
        .then(response => {
            if( response.data.status ){
                this.setState({loginLink:true});
            }
            let errorItems = showErroMsg(response.data.data);
            if(response.data.success===true){
                this.setState({errorMsg:{type:'success',msg:errorItems}, alert:1});
            }else{
                this.setState({errorMsg:{type:'error',msg:errorItems}, alert:1});
            }
        });         
    }  
    
    LoginPage=()=>{
        window.location.href='/auth/login';
    }
     
    hideAlert=()=>{
        this.setState({alert:null})
    }

    render() {
/*         let links = '';
        if( this.state.loginLink ){
            links = <div className='col-md-12'><Link className="site-btn-a form-btn" to="/login">Please Login</Link></div>
        } */
        return (
            <div className="row registraion-form">
                <div className='col-md-12 alert alert-success'>
                {this.state.errorMsg.type!=='' && this.state.alert!==null &&
        <ReactBSAlert
          type={this.state.errorMsg.type}
          style={{ display: "block", marginTop: "-100px" }}
          title=""
          onConfirm={() => this.LoginPage()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {this.state.errorMsg.msg}
        </ReactBSAlert> 
        }      
                </div>
                {/* links */}
                <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
            </div>
        );
    }
}

export default VerifyEmail;