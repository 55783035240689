import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { SendPostData, SendGetData, showErroMsg, showLoader, showErroMsgAlert } from '../../service/fetchData';
import { classicNameResolver } from "typescript";


class AddClass extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        // register form
        class_data:{
          status:1
        },
		  create_message:'',
          isLoading:true
      };
	 }

	 componentDidMount =()=>{
		let id = (this.props.match.params.id) ? this.props.match.params.id : 0;
		if(id>0){
			this.getclasswithID(id);
		}else{
			this.setState({isLoading:false})
		}
	 }


	 getclasswithID=(id)=>{

		SendGetData('/get-classwithid/'+id)
		.then(result => {
		  if(result){
			  console.log(result.data);
			 this.setState({
				class_data: result.data,
				isLoading: false,
			 })  
		  }
		})
		.catch(function (error) {
			 console.log(error);
		});
    }

    handleChange = (e, stateName, type='',length=2) => {
        switch (type) {
          
          case "length":
            if (this.verifyLength(e.target.value, length)) {
              this.setState({ [stateName + "State"]: "has-success" });
            } else {
              this.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
            
            default:
            break;
        }
        
        /* let updateData = Object.assign({}, this.state.class_data, { [e.target.name]: e.target.value })
        this.setState({
            class_data: updateData
        }); */

        if(stateName==='class_name'){
          let updateData = Object.assign({}, this.state.class_data, { 'class_code': e.target.value.substring(0,3).toUpperCase(),[e.target.name]: e.target.value })
          this.setState({
              class_data: updateData
          });
        }
        if(stateName==='class_code'){
          let updateData = Object.assign({}, this.state.class_data, { 'class_code': e.target.value.substring(0,3).toUpperCase() })
          this.setState({
              class_data: updateData
          });
        }
          
    }
    verifyLength = (value, length) => {
        if (value.length >= length) {
          return true;
        }
        return false;
    };
	 handleFormSubmit = e => {
		e.preventDefault(); 
		this.setState({isLoading: true});      
		SendPostData('/save-class',this.state.class_data)
		  .then(result => {
              this.setState({isLoading:false})
				if(result.data.success!==false){
                let errorItems = showErroMsgAlert(result.data.data);
                this.setState({
                    create_message:errorItems,
                    isLoading: false,
                    
                });
                if(this.state.class_data.class_id>0){
                    
                }else{
                    this.setState({
                        class_data:{
                            class_name:'',
                            class_code:''
                    }
                })
                }
				  /* window.location.href = "/admin/app-configs"; */
				}else{
				  let errorItems = showErroMsg(result.data.data);
				  this.setState({
					 create_message:errorItems,
					 isLoading: false
				  });
				}
			 
		  })
		  .catch(error => this.setState({ error: error.message }));
	 };
    render() {
       if(this.state.isLoading===true){
          return showLoader();
       }else{
           
       let class_data=this.state.class_data;
       
       const status_options=[
        { value: 1, label: "Active" },
        { value: 0, label: "Inactive" },
      ];
        return(
            <>
				<div className="content">
					<Row>
						<Col md="12">
							<Form id="RegisterValidation">
									<Card>
										<CardHeader>
											<CardTitle tag="h4">Add/Edit Class Data</CardTitle>

										</CardHeader>
										<CardBody>
											<Row>
												{this.state.create_message!='' && 
												<Col md="12"><div className="alert_bs_div">{this.state.create_message} </div></Col>}
												<Col md="6">
													
															<FormGroup className={`has-label`}>
																<label>Class</label>
																<Input type="text" name="class_name" value={class_data.class_name}  onChange={(e) =>
                                      this.handleChange(e, "class_name", "")
                                  } />
                                  {this.state.class_nameState === "has-danger" ? (
                                  <label className="error">Please enter class name</label>
                                  ) : null}
															</FormGroup>
															<FormGroup>
                                    <label>Status</label>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="status"
                                        value={status_options.filter(function(option) {
                                        return option.value === Number(class_data.status);
                                        })}
                                        onChange={(value) =>{
                                        let updateUserData = Object.assign({}, this.state.class_data, { status: value.value })
                                        this.setState({ class_data: updateUserData })
                                        }
                                        }
                                        options={status_options}
                                        placeholder="Status"
                                        defaultValue={{ label: "Active", value: 1 }}
                                    />
                                    </FormGroup>
														
												</Col>
												<Col md="6">
													
                            <FormGroup className={`has-label`}>
																<label>Class Code</label>
																<Input type="text" name="class_code" maxLength="3" value={class_data.class_code}  onChange={(e) =>
                                      this.handleChange(e, "class_code", "length",3)
                                  } />
                                  {this.state.class_codeState === "has-danger" ? (
                                  <label className="error">Please enter 3 letter class code</label>
                                  ) : null}
															</FormGroup>
                                
															
														
												</Col>
											</Row>
											
											
										</CardBody>
									
										<CardFooter className="text-center">
											<Button color="primary" className="mr-3" onClick={this.handleFormSubmit}>
												Submit
											</Button>
											<Link className="btn btn-primary" to="/admin/classes">Cancel</Link> 
										</CardFooter>
									</Card>
							</Form>
						</Col>
					</Row>   
					</div> 
            </>
        )
       }
    }
}


export default AddClass;