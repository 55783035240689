import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import DropdownFixedNavbar from "components/Navbars/DropdownFixedNavbar.js";

// core components

class HeaderInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      pageHeader:React.createRef()
    };
  }
 
 render(){
  
  
  return (
    <>
    <DropdownFixedNavbar />
      {/* <div className="page-header page-header-small">
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 className="title">{this.props.title}</h1>
              <h4>
                {this.props.desc}
              </h4>
            </Col>
          </Row>
        </div>
      </div> */}
    </>
  );
}
}

export default HeaderInner;
