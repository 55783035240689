
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import { Redirect } from 'react-router';

import DropdownFixedNavbar from "components/Navbars/DropdownFixedNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";
import {authCheckModule} from '../service/fetchData';
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import intakeSidemenu from "intakeSidemenu.js"
import Sidebar from "components/Sidebar/Sidebar.js";


var ps;

class Owner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/owner") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    authCheckModule();
    let currentUser = this.props.user;
    if (currentUser!==undefined && (Number(currentUser.access_id) === 1 || Number(currentUser.access_id) === 2)) {
      return (<Redirect to='/admin/dashboard' />)
    }else{
      
      /* return (
        <>
          <DropdownFixedNavbar />
          <div className="wrapper wrapper-full-page" ref="fullPages">
              <Switch>{this.getRoutes(routes)}</Switch>
              <Footer fluid />
          </div>
        </>
      ); */
      return (
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={intakeSidemenu}
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
          />
          <div className="main-panel" ref="mainPanel">
            <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
            <Switch>{this.getRoutes(routes)}</Switch>
            {
              // we don't want the Footer to be rendered on full screen maps page
              this.props.location.pathname.indexOf("full-screen-map") !==
              -1 ? null : (
                <Footer fluid />
              )
            }
          </div>
          {/* <FixedPlugin
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
            sidebarMini={this.state.sidebarMini}
            handleActiveClick={this.handleActiveClick}
            handleBgClick={this.handleBgClick}
            handleMiniClick={this.handleMiniClick}
          /> */}
        </div>
      )
    }
  }
}

export default Owner;
