import React from "react";
import $ from "jquery";
import axios from 'axios';
import { BaseURL } from '../constants/Config';
import { createBrowserHistory } from 'history';
import loader_img from '../assets/img/kjloader.svg';
import renderHTML from 'react-render-html';
import ReactBSAlert from "react-bootstrap-sweetalert";


export const history = createBrowserHistory();




export let FetchData = (type, userData) => {
    return axios({
        method: 'post',
        url: BaseURL + type,
        data: userData,
    })
    .then(response => {
        if (Object.keys(response.data).length === 0) {
            response = Object.assign({}, response, { data: false });
        }
        return response;
    })
    .catch(error => {
        error = Object.assign({}, error, { data: false });
        return error        
    });
}

export let authCheck = () => {
    if (localStorage.getItem('userData')) {
        try {
            return JSON.parse(localStorage.getItem('userData'));
        } catch{
            /* window.location.reload(); */
        }
    } else {
        
    }
}

export let showErroMsg = (data) => {
    let errorList = Object.keys(data).map((key, i) => {
        const error = data[key][0];
        return (<li key={i}>{error}</li>)
    });
    $(document).ready(function () {
        $(".alert-dismissible").fadeIn("slow");
    });

    window.setTimeout(function () {
        $(".alert-dismissible").fadeOut("slow");
    }, 5000);

    return (
        <div className="alert alert-info alert-dismissible">
            {/* <button type="button" className="close" data-dismiss="alert">&times;</button> */}
            <ul>{errorList}</ul>
        </div>
    );
}

export let hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

export let showErroMsgAlert = (data) => {
    let errorList = Object.keys(data).map((key, i) => {
        const error = data[key][0];
        return (<li key={i} style={{ listStyleType:'none' }}>{error}</li>)
    });
    $(document).ready(function () {
        $(".alert-dismissible").fadeIn("slow");
        $(".alert_bs_div").show();
    });

    window.setTimeout(function () {
        $(".alert-dismissible").fadeOut("slow");
    }, 5000);

    window.setTimeout(function () {
        $(".alert_bs_div").hide();
        //$(".alert_bs_div").html('');
    }, 2000);

    

    return (
        <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title={errorList}
            btnSize=""
            showConfirm={false}
        >
            
        </ReactBSAlert>
    );
}

export let fetchGetData = (type) => {
    return axios({
        method: 'GET',
        url: BaseURL + type,
        data: {}
    })
    .then(result => {
        if (Object.keys(result.data).length === 0) {
            result = Object.assign({}, result, { data: false });
        }
        return result
    })
    .catch(error => {
        error = Object.assign({}, error, { data: false });
        return error
    });
}

export let authCheckModule = () => {
    if (localStorage.getItem('userData')) {
        try {
            return JSON.parse(localStorage.getItem('userData'));
        } catch{
            history.push('/auth/logout');
            window.location.reload();
        }
    } else {
        history.push('/auth/logout');
        window.location.reload();
    }
}

export let SendGetData = (type) => {
    const userDatas = authCheck(); //JSON.parse(localStorage.getItem('userData'));
    return axios({
        method: 'GET',
        url: BaseURL + type,
        headers: { "Authorization": "Bearer " + userDatas.access_token }
    })
    .then(res => { return res })
    .catch(error => {
       /*  authErrorRedirect(); */
        return error
    });
}


export let SendPostData = (type, userData) => {
    const userDatas = authCheck(); // JSON.parse(localStorage.getItem('userData'));
    return axios({
        method: 'POST',
        url: BaseURL + type,
        data: userData,
        headers: { "Authorization": "Bearer " + userDatas.access_token }
    })
    .then(res => { return res })
    .catch(error => {
        return error
    });
}

export let showLoader = () => renderHTML(`<img className="row loader" src=${loader_img} />`);

axios.interceptors.request.use(function (config) {
    config = Object.assign({}, config, { isLoading: true });
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    response = Object.assign({}, response, { isLoading: false });
    return response;
}, function (error) {
    if (error.response.status === 401) {
        history.push('/auth/logout');
        window.location.reload();
    }
    else if (error.response.status !== 200) {
        authCheckModule();
        error = Object.assign({}, error, { errorCode: error.response.status });
    }
    return Promise.reject(error);
});
