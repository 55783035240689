
import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import avatar_img from "../../assets/img/avatar_img.png"

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from 'react-redux';
import { SiteActions } from "actions";
import {UploadsURL} from "../../constants/Config";

class DropdownFixedNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    
    this.props.siteConfigs();
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  render() {
    const site_configs = this.props.site_configs;
    
    const user = this.props.user;
    let prof_url='';
    let avatar=avatar_img;
    if(user!==undefined && user.id!==undefined){
      prof_url='/user-profile';
      if(user.avatar!==null){
        avatar=UploadsURL+user.avatar;
      }
    }else{
      localStorage.setItem('userData','');
    }
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container>
          <div className="navbar-wrapper">
            <NavbarBrand href="/" >
            {site_configs!==undefined && <span className="d-none d-md-block">{site_configs.app_name}</span>}
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            isOpen={this.state.collapseOpen}
            className="justify-content-end"
            navbar
          >
            {(user===undefined || user.id===undefined) &&
            <Nav navbar>
              
              <NavItem>
                {/* <NavLink to="/admin/dashboard" className="nav-link">
                  <i className="nc-icon nc-layout-11" />
                  Dashboard
                </NavLink> */}
              </NavItem>
              <NavItem>
                <NavLink to="/auth/register" className="nav-link">
                  <i className="nc-icon nc-book-bookmark" />
                  Register
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/login" className="nav-link">
                  <i className="nc-icon nc-tap-01" />
                  Login
                </NavLink>
              </NavItem>
              
              
            </Nav>}
            {(user!==undefined && user.id!==undefined) &&
              <Nav navbar>
                <NavItem>
                <NavLink to="/admin/dashboard" className="nav-link">
                  <i className="nc-icon nc-bank" />
                  Dashboard
                </NavLink>
                
              </NavItem>
              <NavItem>
              <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <img src={avatar} width={45} /> {user!==undefined && <span>
                      {user.name}
                    </span>}
                    <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu
                    persist
                    aria-labelledby="navbarDropdownMenuLink"
                    right
                  >
                    <DropdownItem
                      href={prof_url}
                    >
                      Profile
                    </DropdownItem>
                    <DropdownItem
                      href="/auth/logout"
                    >
                      Logout
                    </DropdownItem>
                    
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
              
              </Nav>
            }
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

function mapState(state) {
  const { user, isLogin } = state.AuthReducer;
  const { site_configs } = state.SiteReducers;
  return { user, isLogin, site_configs };
}

const actionCreators = {
  siteConfigs: SiteActions.siteConfigs,
};

export default connect(mapState, actionCreators)(DropdownFixedNavbar);
