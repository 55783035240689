
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import ImageCropper from "../components/ImageCropper";
import { connect } from 'react-redux';
import Select from "react-select";
import { AuthAction } from '../../actions';
import {FetchData, showErroMsg, SendPostData,showLoader,SendGetData,authCheck} from '../../service/fetchData';
import {UploadsURL} from '../../constants/Config';
import ChangePassword from '../../views/pages/ChangePassword';
import { Redirect } from 'react-router';



class AddAdminUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      password: "",
      cancel_url: "",
      name: "",
      phone: "",
      avatar:"",
      email:"",
      avatar_img:'',
      errorMsg:{},
      alert:null,
      isLoading:true,
      isEdit:0,
      password_confirmation: "",
      roleList:[],
      access_id:2
    };
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    this.setState({
        pageNo:this.props.match.params.no,
        user_id:id,
        cancel_url:'/admin/admin-users'
    });
    if(id==undefined){
        this.setState({
            type_id:this.props.match.params.no,
            isLoading:false
        });  
    }else{
      this.setState({isEdit:1});
        this.props.profileInfoAdmin(id);
    }
    
    
}
  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleChangeVal = e => {
    let updateData = Object.assign({}, this.state.profileInfosAdmin, { [e.target.name]: e.target.value });

    this.setState({
      profileInfosAdmin: updateData
    });
} 
addNewUser = e => {
    this.setState({
        [e.target.name]: e.target.value
    });
}

getAllRoles = () => {
  SendGetData('/get-active-roles', {})
    .then(result => {
      let data=result.data;
          let response=[];
          let response_val={};
          for(let i=0; i<data.length; i++){
            response_val={
              value:data[i].id,
              label:data[i].role
            }
            response.push(response_val);
          }
          this.setState({
            roleList: response,
            isLoading: result.isLoading
          })

    })
}

htmlAlert = () => {
  this.setState({
    alert: (
      <ReactBSAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="Select your avatar"
        onConfirm={() => this.updatePicture()}
        onCancel={() => this.hideAlert()}
        btnSize=""
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Submit"
        cancelBtnText="Cancel"
      >
        <ImageCropper cropImg={this.cropImgs} ImgName='avatar' />
      </ReactBSAlert>
    ),
  });
};
hideAlert = () => {
  this.setState({
    alert: null,
  });
}

updatePicture = (e) => {
  let data={
    id:this.state.profileInfosAdmin.id,
    avatar:this.state.avatar
  }
  SendPostData('/photo-update', data)
  .then(response => {
    let updateUserData = Object.assign({}, this.state.profileInfosAdmin, { avatar: response.data.avatar })
    this.setState({
        profileInfosAdmin: updateUserData,
        avatar_img:response.data.avatar,
        alert:null
    });
  })
   
  /* e.preventDefault(); */
  /* this.props.profilePictureUpdate(JSON.stringify(this.state));
  this.closeModal(); */
}
newUserSubmit = e =>{
    e.preventDefault();
    this.setState({isLoading:true});
    if( this.state.name &&
        this.state.email &&
        this.state.password &&
        this.state.password_confirmation  ){
            let new_user_data={
                'name':this.state.name,
                'email':this.state.email,
                'password':this.state.password,
                'agree':1,
                'access_id':this.state.access_id,
                'password_confirmation':this.state.password_confirmation,
                'from_admin':true,
                'status':1,
                'email_verify':0,
                'phone':this.state.phone
            };
        FetchData('/auth/register', new_user_data)
        .then((userData) => {
            this.setState({isLoading:false});
            if(userData.data.success){
                let errorItems = showErroMsg({"message":["User has been created!"]});
                this.setState({ errorMsg:{type:'success', msg:errorItems},
                name:'',
                email:'',
                phone:'',
                password_confirmation:'',
                password:'',
                access_id:'',
                alert:1});
               // window.location.href = "/admin/owners";
            }else{
                let errorItems = showErroMsg(userData.data.data)
               

                this.setState({
                    errorMsg: {type:'error',msg:errorItems},alert:1
                                      
                }); 
            }
        })
        .catch(error => {
            this.setState({
                errorMsg: {type:'error',msg:error},alert:1
            });                        
        });            
    }else{
       /*  let errorItems = showErroMsg({"message":["Fields marked in red asterisks are required."]}) */
        this.setState({ isLoading:false})
        if(this.state.name===''){
          this.setState({nameState:'has-danger'})
        }
        if(this.state.email===''){
          this.setState({emailState:'has-danger'})
        }
        if(this.state.phone===''){
          this.setState({phoneState:'has-danger'})
        }
        if(this.state.password===''){
          this.setState({passwordState:'has-danger'})
        }
        if(this.state.password_confirmation===''){
          this.setState({password_confirmationState:'has-danger'})
        }
        if(this.state.access_id===''){
          this.setState({access_idState:'has-danger'})
        }
        /* this.setState({
            errorMsg: {
                type:'alert alert-danger',
                msg:'All fields are mandatory'
            }                    
        }); */
    }
}

  cropImgs = (img, imgName) => {
    //if(img){
    this.setState({
        avatar: img
    });
    //}
  }

  handleChange = (e, stateName, type,length=0) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(e.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "password":
        if (this.verifyLength(e.target.value, 6)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
        case "number":
        if (this.verifyNumber(e.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(e.target.value,length)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      
        default:
        break;
      }
      if(this.state.isEdit===1){
        let updateData = Object.assign({}, this.state.profileInfosAdmin, { [e.target.name]: e.target.value })
        this.setState({
          profileInfosAdmin: updateData
        });
      }else{
        this.setState({
          [e.target.name]: e.target.value
      });
      }
      
  }




  // function that returns true if value is email, false otherwise
  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if two strings are equal
  
  verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  
  registerClick = () => {
    if (this.state.email === "") {
      this.setState({ email: "has-danger" });
    }
    if (this.state.name === "") {
        this.setState({ name: "has-danger" });
    }
    
    
  };

  dataSubmit = e => {
    e.preventDefault();
    this.props.profileUpdate(JSON.stringify(this.state.profileInfosAdmin),this.state.profileInfosAdmin.id);
  }
  
  render() {
    if(this.state.isLoading){
      return showLoader();
    }else{
    let { profileInfosAdmin } = this.state;
    let avatar_img='';
    let roleList=this.state.roleList;
    let {emailState,nameState,phoneState,passwordState,password_confirmationState,avatar,access_idState,access_id}=this.state;
    
    if(profileInfosAdmin!==undefined){
      let uData=JSON.parse(localStorage.getItem('userData'));
      if((Number(uData.access_id)===1) || (Number(uData.access_id)===2) || (Number(uData.access_id)>2 && uData.access_id<profileInfosAdmin.access_id)){
      let cancel_url=this.state.cancel_url;
     
      if(profileInfosAdmin.avatar){
        avatar_img=UploadsURL+profileInfosAdmin.avatar;
      }
      const status_options=[
        { value: 2, label: "Pending" },
        { value: 1, label: "Active" },
        { value: 0, label: "Inactive" },
      ];
      

    return (
      <>
        <div className="content">
          <Row>
            <Col md="6">
              <Form id="RegisterValidation">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Edit User</CardTitle>
                  </CardHeader>
                  <CardBody>
                  <div className="alert_bs_div">{this.props.apiMessage}</div>
                  <FormGroup className={`has-label ${nameState}`}>
                      <label>Name *</label>
                      <Input
                        id="Name"
                        name="name"
                        type="text"
                        autoComplete="off"
                        value={profileInfosAdmin.name}
                        onChange={(e) =>
                          this.handleChange(e, "name", "length", 2)
                        }
                        maxLength={100}
                      />
                      {this.state.nameState === "has-danger" ? (
                        <label className="error">The name must be between 2 and 100 characters.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${emailState}`}>
                      <label>Email Address *</label>
                      <Input
                      id="email"
                        name="email"
                        type="email"
                        value={profileInfosAdmin.email}
                        onChange={(e) =>
                          this.handleChange(e, "email", "email")
                        } 
                        disabled={true}
                      />
                      {this.state.emailState === "has-danger" ? (
                        <label className="error"><br />
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </FormGroup>
                    
                    <FormGroup
                      className={`has-label ${phoneState}`}
                    >
                      <label>Phone Number</label>
                      <Input
                        id="phone"
                        name="phone"
                        type="text"
                        autoComplete="off"
                        value={profileInfosAdmin.phone}
                        onChange={(e) =>
                          this.handleChange(e, "phone", "number")
                        }
                      />
                      {this.state.phoneState ===
                      "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="status"
                            value={status_options.filter(function(option) {
                              return option.value === Number(profileInfosAdmin.status);
                            })}
                            onChange={(value) =>{
                              let u=authCheck();
                              if(u!== undefined && u.access_id!=1){
                                this.setState({alert:<ReactBSAlert
                                  warning
                                  style={{ display: "block", marginTop: "-100px" }}
                                  title="You do not have the privillege to disable a user. Please contact the Super Admin"
                                  onConfirm={() => this.hideAlert()}
                                  btnSize=""
                                  confirmBtnBsStyle="info"
                                  cancelBtnBsStyle="danger"
                                  confirmBtnText="OK"
                                  cancelBtnText="Cancel"
                                >
                                  
                                </ReactBSAlert>})
                              }else{
                                let updateUserData = Object.assign({}, this.state.profileInfosAdmin, { status: value.value })
                                this.setState({ profileInfosAdmin: updateUserData })
                              }
                              
                            }
                            }
                            options={status_options}
                            placeholder="Status"
                          />
                    </FormGroup>
                    
                    
                    <div className="category form-category">
                      * Required fields
                    </div>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="info" onClick={this.dataSubmit}>
                      Update Profile
                    </Button>
                    <Link className="btn btn-info ml-3" to={cancel_url}> 
                      Cancel    
                    </Link>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
            <Col md="6">
              <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Change Avatar</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      {avatar_img!='' && 
                      <img src={avatar_img} />
                    }
                    </FormGroup>
                  <FormGroup
                      className={`has-label ${avatar}`}
                    >

                     <Button
                      className="btn-round"
                      color="default"
                      onClick={this.htmlAlert}
                      outline
                    >
                      Change Avatar
                    </Button>
                    {this.state.alert}
                    </FormGroup>
                  </CardBody>
              </Card>
              <ChangePassword user_id={profileInfosAdmin.id} />
            
            </Col>
            
          </Row>
        </div>
      </>
    );
  }else{
    return (<Redirect to='/admin/dashboard' />)
  }
}
  else{
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form id="RegisterValidation">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add User</CardTitle>
                    {this.state.errorMsg.msg}
                  </CardHeader>
                  <CardBody>
                    
                  <FormGroup className={`has-label ${nameState}`}>
                      <label>Name *</label>
                      <Input
                        id="Name"
                        name="name"
                        type="text"
                        autoComplete="off"
                        value={this.state.name}
                        onChange={(e) =>
                          this.handleChange(e, "name", "length", 2)
                        }
                        maxLength={100}
                      />
                      {this.state.nameState === "has-danger" ? (
                        <label className="error">The name must be between 2 and 100 characters.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${emailState}`}>
                      <label>Email Address *</label>
                      <Input
                      id="email"
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.handleChange(e, "email", "email")
                        }
                      />
                      {this.state.emailState === "has-danger" ? (
                        <label className="error">
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </FormGroup>
                    
                    <FormGroup
                      className={`has-label ${phoneState}`}
                    >
                      <label>Phone Number</label>
                      <Input
                        id="phone"
                        name="phone"
                        type="text"
                        autoComplete="off"
                        value={this.state.phone}
                        onChange={(e) =>
                          this.handleChange(e, "phone", "number")
                        } 
                      />
                      {this.state.phoneState ===
                      "has-danger" ? (
                        <label className="error">Please enter a valid phone number</label>
                      ) : null}
                    </FormGroup>
                    
                    <FormGroup
                      className={`has-label ${passwordState}`}
                    >
                      <label>Password</label>
                      <Input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="off"
                        value={this.state.password}
                        onChange={(e) =>
                          this.handleChange(e, "password", "password")
                        }
                      />
                      {this.state.passwordState ===
                      "has-danger" ? (
                        <label className="error">The password must be at least 6 characters.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup
                      className={`has-label  ${password_confirmationState}`}
                    >
                      <label>Confirm Password</label>
                      <Input
                        id="password_confirmation"
                        name="password_confirmation"
                        type="password"
                        autoComplete="off"
                        value={this.state.password_confirmation}
                        onChange={(e) =>
                          this.handleChange(e, "password_confirmation", "password")
                        }
                      />
                      {this.state.password_confirmationState ===
                      "has-danger" ? (
                        <label className="error">The password must be at least 6 characters.</label>
                      ) : null}
                    </FormGroup>
                    
                    
                    <div className="category form-category">
                      * Required fields
                    </div>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="info" onClick={this.newUserSubmit}>
                      Add Admin User
                    </Button>
                    <Link className="btn btn-info ml-3" to={this.state.cancel_url}> 
                      Cancel    
                    </Link>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
            
          </Row>
        </div>
      </>
    );
      }
  }
  }
}

function mapState(state) {
  let { profileInfosAdmin, isLogin, isLoading } = state.AuthReducer;
  const { apiMessage } = state.AlertReducer;
  return { profileInfosAdmin, isLogin, isLoading, apiMessage };
}

const actionCreators = {
  profileInfoAdmin: AuthAction.profileInfoAdmin,
  profileUpdateAdmin: AuthAction.profileUpdateAdmin,
  profilePictureUpdate: AuthAction.profilePictureUpdate,
  profileUpdate: AuthAction.profileUpdate
};

export default connect(mapState, actionCreators)(AddAdminUser);