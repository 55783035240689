import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { SendPostData, SendGetData, showErroMsg, showLoader, showErroMsgAlert } from '../../service/fetchData';


class AddTerm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        // register form
        term_data:{
          status:1
        },
		  create_message:'',
          isLoading:true
      };
	 }

	 componentDidMount =()=>{
		let id = (this.props.match.params.id) ? this.props.match.params.id : 0;
		if(id>0){
			this.getTermwithID(id);
		}else{
			this.setState({isLoading:false})
		}
	 }


	 getTermwithID=(id)=>{

		SendGetData('/get-termwithid/'+id)
		.then(result => {
		  if(result){
			  console.log(result.data);
			 this.setState({
				term_data: result.data,
				isLoading: false,
			 })  
		  }
		})
		.catch(function (error) {
			 console.log(error);
		});
    }

    handleChange = (e, stateName, type='') => {
        switch (type) {
          
          case "length":
            if (this.verifyLength(e.target.value, 2)) {
              this.setState({ [stateName + "State"]: "has-success" });
            } else {
              this.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
            
            default:
            break;
        }
        let updateData = Object.assign({}, this.state.term_data, { [e.target.name]: e.target.value })
        this.setState({
            term_data: updateData
        });
          
    }
    verifyLength = (value, length) => {
        if (value.length >= length) {
          return true;
        }
        return false;
    };
	 handleFormSubmit = e => {
		e.preventDefault(); 
		this.setState({isLoading: true});      
		SendPostData('/save-term',this.state.term_data)
		  .then(result => {
              this.setState({isLoading:false})
				if(result.data.success!==false){
                let errorItems = showErroMsgAlert(result.data.data);
                this.setState({
                    create_message:errorItems,
                    isLoading: false,
                    
                });
                if(this.state.term_data.term_id>0){
                    
                }else{
                    this.setState({
                    term_data:{
                        term_name:''
                    }
                })
                }
				  /* window.location.href = "/admin/app-configs"; */
				}else{
				  let errorItems = showErroMsg(result.data.data);
				  this.setState({
					 create_message:errorItems,
					 isLoading: false
				  });
				}
			 
		  })
		  .catch(error => this.setState({ error: error.message }));
	 };
    render() {
       if(this.state.isLoading===true){
          return showLoader();
       }else{
           
       let term_data=this.state.term_data;
       
       const status_options=[
        { value: 1, label: "Active" },
        { value: 0, label: "Inactive" },
      ];
        return(
            <>
				<div className="content">
					<Row>
						<Col md="12">
							<Form id="RegisterValidation">
									<Card>
										<CardHeader>
											<CardTitle tag="h4">Add/Edit Term Data</CardTitle>

										</CardHeader>
										<CardBody>
											<Row>
												{this.state.create_message!='' && 
												<Col md="12"><div className="alert_bs_div">{this.state.create_message} </div></Col>}
												<Col md="6">
												
															<FormGroup className={`has-label`}>
																<label>Term</label>
																<Input type="text" name="term_name" value={term_data.term_name}  onChange={(e) =>
                                                                    this.handleChange(e, "term_name", "length")
                                    } />
                                    {this.state.term_nameState === "has-danger" ? (
                                    <label className="error">Please enter term</label>
                                    ) : null}
															</FormGroup>
                              <FormGroup>
                                <label>Status</label>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="status"
                                    value={status_options.filter(function(option) {
                                    return option.value === Number(term_data.status);
                                    })}
                                    onChange={(value) =>{
                                    let updateUserData = Object.assign({}, this.state.term_data, { status: value.value })
                                    this.setState({ term_data: updateUserData })
                                    }
                                    }
                                    options={status_options}
                                    placeholder="Status"
                                />
                                </FormGroup>
															
														
												</Col>
												<Col md="6">
													
                            <FormGroup className={`has-label`}>
																<label>Term Order Id</label>
																<Input type="text" name="term_order" value={term_data.term_order}  onChange={(e) =>
                                                                    this.handleChange(e, "term_order", "")
                                                                } />
                                                                
															</FormGroup>
                                                      
															
														
												</Col>
											</Row>
											
											
										</CardBody>
									
										<CardFooter className="text-center">
											<Button color="info" className="mr-3" onClick={this.handleFormSubmit}>
												Submit
											</Button>
											<Link className="btn btn-info" to="/admin/terms">Cancel</Link> 
										</CardFooter>
									</Card>
							</Form>
						</Col>
					</Row>   
					</div> 
            </>
        )
       }
    }
}


export default AddTerm;