
import React from "react";

import { SendGetData, showLoader } from '../../service/fetchData';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import { Link } from "react-router-dom";


class UserRoles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecord:0,
            per_page:0,
            offset:0,
            pageNo:1,
            page:0,
            pagination:{},
            roleList:{},
            isLoading:true,
            alert:null,
            roleEditData:{}
    }
  }
  getAllRoles = () => {
    SendGetData('/get-all-roles', {})
      .then(result => {
        this.setState({
            roleList: result.data,
            isLoading: result.isLoading
        })

      })
  }

  

  

  componentDidMount() {
    this.getAllRoles();
  }
  render() {
    let b_list = '';
    if (this.state.isLoading) {
      b_list = showLoader();
    } else {

      const { roleList } = this.state;
      let uData=JSON.parse(localStorage.getItem('userData'));
      if(roleList!=='' && roleList!==undefined){
        b_list = Object.keys(roleList).map((page, k) => {
            let u_key = 'app-' + roleList[k].id;
            let editURL = "/admin/edit-role/" + roleList[k].id;
            let active_class = 'btn-sm btn-round btn-icon btn btn-success';
            let st='Active';
            let t_id='t_id'+roleList[k].id;
            if (Number(roleList[k].status == 0)) {
              st='Inactive';
            active_class = 'btn-sm btn-round btn-icon btn btn-youtube';
          }
            if(uData.access_id<=roleList[k].id){
            return (
              <tr key={u_key}>
                <td>{roleList[k].id}</td>
                <td>{roleList[k].role}</td>
                <td>
                        <span className={active_class} id={t_id}>
                        </span>
                        <UncontrolledTooltip
                            delay={0}
                            target={t_id}
                          >
                            {st}
                          </UncontrolledTooltip>
                        </td>
                <td>
                {((Number(uData.access_id)===1) || (Number(uData.access_id)!==1 && uData.access_id<roleList[k].id)) && 
                <Link className="page_editIcon btn-sm btn-icon btn-primary mr-3" to={editURL}><i className="fa fa-edit" aria-hidden="true"></i></Link>
                }
                </td>
    
              </tr>
            )
            }
          })
      }
      
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              {this.state.alert!==null && 
                <div>{this.state.alert}</div>
              }
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="10">
                    <CardTitle tag="h4">User Roles</CardTitle>
                    </Col>
                  <Col md="2" className="text-right">
                    {<Link className="btn btn-info" to="/admin/add-role"> 
                      <span className="btn-label">
                        <i className="nc-icon nc-simple-add" />
                      </span>
                      Add
                    </Link>}
                    </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                  
                  <Table responsive striped>
                    <thead className="text-primary">
                      <tr>
                        <th> ID</th>
                        <th>Roles</th>
                        <th>Status</th>
                       <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                     {b_list}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}



export default UserRoles;
