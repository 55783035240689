
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { SendGetData, showLoader } from '../../service/fetchData';

class EmailsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecord:0,
            per_page:0,
            offset:0,
            pageNo:1,
            page:0,
            pagination:{},
            emailList:'',
            isLoading:true
    }
  }
  getAllemailList = () => {
    SendGetData('/get-emailist', {})
      .then(result => {
        this.setState({
          emailList: result.data,
          isLoading: result.isLoading
        })

      })
  }
  componentDidMount() {
    this.getAllemailList();
  }
  render() {
    let b_list = '';
    if (this.state.isLoading) {
      b_list = showLoader();
    } else {

      const { emailList } = this.state;
      if(emailList!=='' && emailList!==undefined){
        b_list = Object.keys(emailList).map((page, k) => {
            let editURL = "/admin/edit-email-content/" + emailList[k].id;
            let u_key = 'email-' + emailList[k].id;
            return (
              <tr key={u_key}>
                <td>{emailList[k].email_title}</td>
                <td><Link className="page_editIcon" to={editURL}><i className="fa fa-edit" aria-hidden="true"></i></Link>
                </td>
    
              </tr>
            )
          })
      }
      
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Email Content Management</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive striped>
                    <thead className="text-primary">
                      <tr>
                        <th>Email title</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                     {b_list}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}



export default EmailsContent;
