import {AllConstants} from '../constants/AllConstants';
import { authCheck } from '../service/fetchData';


let isLoading = {isLoading:true};
let user = authCheck();

const initialState = user ? { isLogin: true, user, isLoading } : {};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case AllConstants.LOGIN_SUCCESS:
            
            return{
                ...state,
                isLogin: true,
                user: action.payload          
            }
            
        case AllConstants.LOGOUT_SUCCESS:
            return{ isLogin: false }     
        case AllConstants.GET_USER_PROFILE:
            return{
               ...state,
               profileInfos: action.payload,
               isLoading: action.isLoading     
            } 
        case AllConstants.GET_USER_PROFILE_ADMIN:
            return{
                ...state,
                profileInfosAdmin: action.payload,
                isLoading: action.isLoading     
            }             
        default:
            return state            
    }
};

export default AuthReducer;