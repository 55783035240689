import React, { Component } from 'react';
import { fetchGetData, showErroMsg, showLoader } from "../../service/fetchData";
import ReactBSAlert from "react-bootstrap-sweetalert";


class MediaPage extends Component {

    constructor(props) {
        super(props);
        this.state ={
            isLoading:true,
            errorMsg:{},
            loginLink:false,
            alert:null
        }
    }    

    componentDidMount() {
        let id = this.props.match.params.id ? this.props.match.params.id : 0;
        if(id>0){
            fetchGetData(`/media-link/${id}`)
            .then(response => {
                
                if(response.data.success===true){
                    window.location.href=response.data.media_link;
                }else{
                    let errorItems = showErroMsg(response.data.data);
                    this.setState({errorMsg:{type:'error',msg:errorItems}, alert:1,isLoading:false});
                }
            });    
        }
             
    }  
    
    LoginPage=()=>{
        window.location.href='/auth/login';
    }
     
    hideAlert=()=>{
        this.setState({alert:null})
    }

    render() {
/*         let links = '';
        if( this.state.loginLink ){
            links = <div className='col-md-12'><Link className="site-btn-a form-btn" to="/login">Please Login</Link></div>
        } */
        if( this.state.isLoading ){
            return showLoader();
        }else{
        return (
            <div className="row registraion-form">
                <div className='col-md-12 alert alert-success'>
                {this.state.errorMsg.type!=='' && this.state.alert!==null &&
        <ReactBSAlert
          type={this.state.errorMsg.type}
          style={{ display: "block", marginTop: "-100px" }}
          title=""
          onConfirm={() => this.LoginPage()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {this.state.errorMsg.msg}
        </ReactBSAlert> 
        }      
                </div>
                {/* links */}
                <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
            </div>
        );
        }
    }
}

export default MediaPage;